/*eslint-disable*/
import React, { useContext, useState } from 'react';
import { Grid, Message, Segment } from 'semantic-ui-react';
import {
  ProductDetailsContext,
  SelectedRoleContext,
} from '../../../../context/UserContext';
import {
  Maybe,
  ProductType,
  Role,
  useEditProjectDetailsMutation,
  useProjectInfoBoxQuery,
} from '../../../../graphql/generated/graphql';
import { PROJECT_INFOBOX } from '../../../../graphql/queries/VersionQuerys';
import InfoboxPlaceholder from '../skeletons/InfoboxSkeleton';
import DescriptionItem from './components/DescriptionItem';
import EditProductButton from './components/EditProductButton';
import InfoBoxHeader from './components/InfoBoxHeader';
import PriorityItem from './components/PriorityItem';
import ProductTypeItem from './components/ProductTypeItem';
import StandardSoftwareItem from './components/StandardSoftwareItem';
import TargetDateItem from './components/TargetDate';

interface Props {
  maxDescriptionLines?: number;
}

/**
 * @param {string} props {maxDescriptionLines}
 * @returns {JSX.Element} Info Box Component
 */
const ProjectInfoBox = (props: Props): JSX.Element => {
  const { maxDescriptionLines = 6 } = props;
  const [open, setOpen] = useState<boolean>(true);
  const [editing, setEditing] = useState<boolean>(false);
  const [description, setDescription] = useState<Maybe<string> | undefined>();
  const [types, setTypes] = useState<ProductType[] | undefined>();
  const [standardSoftware, setStandardSoftware] = useState<
    boolean | undefined
  >();
  const [targetDate, setTargetDate] = useState<string>();
  const { selectedRole } = useContext(SelectedRoleContext);
  const { versionId } = useContext(ProductDetailsContext);

  const { loading, data, error } = useProjectInfoBoxQuery({
    variables: { id: versionId },
    onCompleted(result) {
      const version = result.Version;
      const product = result.Version?.product;
      setDescription(product?.description);
      setTypes(product?.type);
      setTargetDate(version?.targetDate);
      setStandardSoftware(product?.standardSoftware);
    },
  });

  const [editDetailsMutation] = useEditProjectDetailsMutation({
    variables: {
      versionId,
      targetDate: targetDate,
      types,
      description,
      standardSoftware,
    },
    refetchQueries: [PROJECT_INFOBOX],
  });

  if (error)
    return (
      <Message
        id="ProjectInfoBoxError"
        error
        icon="times circle"
        content={`Error ${error.message}`}
      />
    );

  if (loading) return <InfoboxPlaceholder />;

  if (data) {
    return (
      <Segment id="InfoBox" raised>
        <InfoBoxHeader open={open} setOpen={setOpen} />
        {open && (
          <Grid relaxed padded>
            {/* First Row */}
            <Grid.Row>
              <Grid.Column width={10}>
                <DescriptionItem
                  editing={editing}
                  description={description}
                  setDescription={setDescription}
                  maxDescriptionLines={maxDescriptionLines}
                />
              </Grid.Column>
              <Grid.Column width={6} textAlign="center">
                <EditProductButton
                  editing={editing}
                  setEditing={setEditing}
                  types={types}
                  editDetailsMutation={editDetailsMutation}
                />
              </Grid.Column>
            </Grid.Row>
            {/* Second Row */}
            <Grid.Row columns="equal" stretched>
              <Grid.Column>
                <TargetDateItem
                  editing={editing}
                  targetDate={targetDate}
                  setTargetDate={setTargetDate}
                />
              </Grid.Column>
              <Grid.Column>
                <ProductTypeItem
                  editing={editing}
                  productType={types}
                  setProductType={setTypes}
                />
              </Grid.Column>
              {[Role.oso, Role.legal].includes(selectedRole) && (
                <Grid.Column>
                  <PriorityItem priority={data?.Version?.priority} />
                </Grid.Column>
              )}
              <Grid.Column>
                <StandardSoftwareItem
                  standardSoftware={standardSoftware}
                  setStandardSoftware={setStandardSoftware}
                  editing={editing}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </Segment>
    );
  }
  return <div />;
};

export default ProjectInfoBox;
