import React, { Suspense, useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Loader, Message, Segment, SegmentGroup } from 'semantic-ui-react';
import NoAccessPage from '../components/navigation/NoAccessPage';
import ProductDetailsFootBar from '../components/products/productDetails/footbar/ProductDetailsFootBar';

import {
  ProductDetailsContext,
  SelectedRoleContext,
} from '../context/UserContext';
import {
  DirectoryRole,
  Role,
  useProductDetailsContextQuery,
} from '../graphql/generated/graphql';
import ProductDetailsHeader from '../components/products/productDetails/header/ProductDetailsHeader';
import ProductDetailsSegment from '../components/products/productDetails/ProductDetailsSegment';

/**
 * Product Details Page switches the Details views with respect to the selected user role
 *
 * @returns {JSX.Element} ProductDetails
 */
const ProductDetails = (): JSX.Element => {
  const role = useContext(SelectedRoleContext);
  const { selectedRole, setSelectedRole } = role; // had to destructure cause of es-lint!
  const userRole = selectedRole;
  const { id: versionId } = useParams();
  const { data, error } = useProductDetailsContextQuery({
    variables: { id: versionId || '', selectedRole },
  });

  useEffect(() => {
    if (data && data.Version) {
      const noGuestNoManager = data.Version.accessRoles
        .filter((r: DirectoryRole) => r !== DirectoryRole.guest)
        .map((r: DirectoryRole) => {
          return r === DirectoryRole.manager
            ? (DirectoryRole.developer as unknown as Role)
            : (r as unknown as Role);
        });
      if (!noGuestNoManager.includes(userRole) && noGuestNoManager.length > 0) {
        setSelectedRole(noGuestNoManager[0]);
      }
    }
  }, [data, setSelectedRole, userRole]);

  const productDetailsContext = useMemo(() => {
    if (data && data.Version && data.Version.product) {
      const { externalId, groupCompany, contactPerson } =
        data.Version.product.transfer || {};
      const isGroupTransfer =
        externalId !== null || groupCompany !== null || contactPerson !== null;
      return {
        versionId: data.Version.id,
        productId: data.Version.product.id,
        isProject: data.Version.product.isProject,
        finishedImport: data.Version.finishedImport || false,
        accessRoles: data.Version.accessRoles,
        authorizations: data.Authorizations,
        processStatusAsString: data.Version.processStatusAsString,
        tags: data.Version.product.tags || [],
        inLegalCheckOrDone: data.Version.product.inLegalCheckOrDone,
        isGroupTransfer,
      };
    }
    return {
      versionId: '',
      productId: '',
      isProject: false,
      finishedImport: false,
      accessRoles: [],
      authorizations: [],
      processStatusAsString: '',
      tags: [],
      inLegalCheckOrDone: false,
      isGroupTransfer: false,
    };
  }, [data]);

  if (error) {
    if (
      error.graphQLErrors.map((e) => e?.extensions?.code).includes('FORBIDDEN')
    ) {
      return <NoAccessPage />;
    }
    return (
      <Segment
        textAlign="center"
        id="errorSegment"
        placeholder
        style={{ height: '100vh' }}
      >
        <Message
          id="ProductDetailsError"
          error
          icon="times circle"
          content={`Error ${error.message}`}
        />
      </Segment>
    );
  }

  if (data && data.Version && data.Version.product) {
    return (
      <ProductDetailsContext.Provider value={productDetailsContext}>
        <SegmentGroup className="product-details-segment-group">
          <Suspense fallback={null}>
            <ProductDetailsHeader />
            <ProductDetailsSegment />
            <ProductDetailsFootBar />
          </Suspense>
        </SegmentGroup>
      </ProductDetailsContext.Provider>
    );
  }

  return <Loader disabled />;
};

export default ProductDetails;
