import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Icon, Label, Menu, Message, Segment } from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../context/UserContext';
import {
  Role,
  useAllUsersQuery,
  useProductDetailsContactsQuery,
} from '../../../../graphql/generated/graphql';
import {
  UPDATE_DEVELOPER_TEAM,
  UPDATE_GUEST_TEAM,
  UPDATE_LEGAL_REVIEWER,
  UPDATE_MANAGER_TEAM,
  UPDATE_OSO_REVIEWER,
  UPDATE_SUPPLIER_TEAM,
} from '../../../../graphql/mutations/VersionMutations';
import DevelopmentTeamIcon from './DevelopmentTeamIcon';
import { ContactOption, Contacts, Department } from './helper/types';
import ReviewerCard from './ReviewerCard';
import TeamCard from './TeamCard';
import GenericClosedSegmentSkeleton from '../skeletons/GenericClosedSegmentSkeleton';
import { reviewerOptions, optionsByRole, guestOptions } from './helper/options';

type Props = {
  show?: boolean;
};

/**
 * Contact Segment for User Product Details.
 *
 * @param {object} props Name, productId and show if it should not be collapsed by default.
 * @returns {JSX.Element} ProductContactsSegment.
 */
const ProductContactsSegment = (props: Props): JSX.Element => {
  const { show = false } = props;
  const [t, i18n] = useTranslation(['users']);
  const i18nLang = i18n.language;
  const [open, setOpen] = useState(show);
  const { versionId, authorizations } = useContext(ProductDetailsContext);
  const readOnly = !authorizations?.includes('UpdateProductContacts');

  const { error, loading, data } = useProductDetailsContactsQuery({
    variables: { id: versionId },
  });
  const userData = useAllUsersQuery();
  const [contacts, setContacts] = useState({} as Contacts);
  const [contactCount, setContactCount] = useState(0);

  useEffect(() => {
    if (data) {
      setContacts({
        legalReviewer: data.Version?.legalReviewer,
        osoReviewer: data.Version?.osoReviewer,
        managerTeam: data.Version?.product?.managerTeam
          ? data.Version?.product?.managerTeam
          : [],
        developmentTeam: data.Version?.product?.developmentTeam || [],
        supplierTeam: data.Version?.product?.supplierTeam || [],
        guestTeam: data.Version?.product?.guestTeam || [],
      });
    }
  }, [data]);

  useEffect(() => {
    setContactCount(
      (contacts.legalReviewer ? 1 : 0) +
        (contacts.osoReviewer ? 1 : 0) +
        (contacts.managerTeam ? 1 : 0) +
        (contacts.developmentTeam ? contacts.developmentTeam.length : 0) +
        (contacts.supplierTeam ? contacts.supplierTeam.length : 0) +
        (contacts.guestTeam ? contacts.guestTeam.length : 0)
    );
  }, [contacts]);

  // users with brandDirectory access as reviewers
  const legals = data?.Version?.product?.brandDirectory?.legals || [];
  const osos = data?.Version?.product?.brandDirectory?.osos || [];

  if (error)
    return (
      <Message
        id="ProductContactsErrorMsg"
        error
        icon="times circle"
        content={`Error ${error.message}`}
      />
    );
  if (loading)
    return (
      <GenericClosedSegmentSkeleton
        image
        labels="all"
        lines={1}
        linesLength="full"
      />
    );
  return (
    <Segment
      className="contacts"
      raised
      id={`ProductContactsSegment${versionId}`}
    >
      <Menu id="ProductContactsMenuTabs" secondary compact>
        <Menu.Item>
          <Icon
            id="ProductContactsAccordion"
            onClick={() => setOpen(!open)}
            name={open ? 'chevron up' : 'chevron down'}
            color={open ? 'blue' : 'black'}
          />
        </Menu.Item>
        <Menu.Item style={{ fontWeight: 'bold', fontSize: '16px' }}>
          {t('product-contacts')}
          <Label circular id="CountLabel" content={contactCount} />
          {!readOnly && (
            <span>
              <Icon
                id={`ProductContactsGuideIcon-${i18nLang}`}
                size="large"
                name="question circle"
                disabled={!authorizations.includes('UpdateVersion')}
                style={{ marginLeft: '0.5rem', display: 'none' }} // TODO: delete display none when guide is ready
              />
            </span>
          )}
        </Menu.Item>
      </Menu>
      {open ? (
        <div>
          {data && data.Version && data.Version.product && (
            <Grid columns="equal" centered stackable>
              <Grid.Column stretched className="pseudo-divider-right">
                <ReviewerCard
                  contact={contacts.legalReviewer}
                  department={Department.LEGAL}
                  mutation={UPDATE_LEGAL_REVIEWER}
                  options={reviewerOptions(legals, contacts.legalReviewer?.id)}
                  icon="legal"
                />
                <ReviewerCard
                  contact={contacts.osoReviewer}
                  department={Department.OSO}
                  mutation={UPDATE_OSO_REVIEWER}
                  options={reviewerOptions(osos, contacts.osoReviewer?.id)}
                  icon="briefcase"
                />
              </Grid.Column>
              <Grid.Column stretched>
                {contacts.managerTeam && (
                  <TeamCard
                    team={contacts.managerTeam}
                    department={Department.MANAGER}
                    mutation={UPDATE_MANAGER_TEAM}
                    options={optionsByRole(
                      Role.developer,
                      userData.data?.User as ContactOption[]
                    )}
                    icon="user"
                  />
                )}
              </Grid.Column>
              <Grid.Column stretched>
                {contacts.developmentTeam && (
                  <TeamCard
                    team={contacts.developmentTeam}
                    department={Department.DEVELOPMENT}
                    mutation={UPDATE_DEVELOPER_TEAM}
                    options={optionsByRole(
                      Role.developer,
                      userData.data?.User as ContactOption[]
                    )}
                    icon={<DevelopmentTeamIcon />}
                  />
                )}
              </Grid.Column>
              <Grid.Column stretched>
                {contacts.supplierTeam && (
                  <TeamCard
                    team={contacts.supplierTeam}
                    department={Department.SUPPLIER}
                    mutation={UPDATE_SUPPLIER_TEAM}
                    options={optionsByRole(
                      Role.supplier,
                      userData.data?.User as ContactOption[]
                    )}
                    icon="dolly"
                    maxFeedLength={1}
                    inLegalCheckOrDone={data.Version.product.inLegalCheckOrDone}
                  />
                )}
              </Grid.Column>
              <Grid.Column stretched>
                {contacts.supplierTeam && (
                  <TeamCard
                    team={contacts.guestTeam}
                    department={Department.GUEST}
                    mutation={UPDATE_GUEST_TEAM}
                    options={guestOptions(
                      userData.data?.User as ContactOption[]
                    )}
                    icon="id badge"
                  />
                )}
              </Grid.Column>
            </Grid>
          )}
        </div>
      ) : null}
    </Segment>
  );
};

export default ProductContactsSegment;
