import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../context/UserContext';
import {
  DependencySelection,
  SelectionType,
  useGetRequirementDetailsQuery,
} from '../../../../graphql/generated/graphql';
import CustomPagination from '../../listComponents/pagination/CustomPagination';
import { usePagination } from '../dependencySegment/dependencyList/productVersionDependencyList/PVDependencyListHooks';
import RequirementComponent from './RequirementComponent';

type Props = {
  authorized: boolean;
  requirementId: string;
  dependencySelections: DependencySelection[];
  setDependencySelections: (
    dependencySelections: DependencySelection[]
  ) => void;
  versionSelectionInfo: SelectionType | null;
  individualSelection: boolean;
  dependenciesToCheck: DependencySelection[];
  setDepsToCheck: (dependencySelections: DependencySelection[]) => void;
};

/**
 *@returns {boolean} List as a table of Products for a requirement
 @param {Props} props props
 */
const RequirementComponentsList = (props: Props): JSX.Element => {
  const {
    authorized,
    requirementId,
    dependencySelections,
    setDependencySelections,
    versionSelectionInfo,
    individualSelection,
    dependenciesToCheck,
    setDepsToCheck,
  } = props;
  const { t } = useTranslation(['requirements']);
  const { versionId } = useContext(ProductDetailsContext);
  const { data, loading, error } = useGetRequirementDetailsQuery({
    variables: { versionId, requirementId },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const componentsAmount = data?.GetRequirementDetails?.length || 0;
  const [activePageState, setActivePageState] = useState(1);
  const [pages, setPages] = useState(1);
  const itemsPerPage = 5;
  const components =
    data?.GetRequirementDetails?.slice(
      (activePageState - 1) * itemsPerPage,
      activePageState * itemsPerPage
    ) || [];

  usePagination(
    activePageState,
    componentsAmount,
    pages,
    setPages,
    setActivePageState,
    itemsPerPage
  );

  // populating a state with all dependencies and using them to check the selection type and when all equal
  useEffect(() => {
    if (data && dependenciesToCheck.length === 0) {
      setDepsToCheck(
        data?.GetRequirementDetails?.map((detail) => {
          return {
            dependencyId: detail.dependencyId,
            selectionType:
              detail.selectionInfo !== null // checkinh if it has a selection or not
                ? detail.selectionInfo
                : versionSelectionInfo, //  if version selection Type was selected for all deps !
          };
        }) || []
      );
    }
  }, [
    data,
    setDepsToCheck,
    individualSelection,
    versionSelectionInfo,
    dependenciesToCheck,
  ]);

  return (
    <>
      <Table id="RequirementsProductsListTable" celled compact definition>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>{t('tableHeader.product')}</Table.HeaderCell>
            <Table.HeaderCell>{t('tableHeader.license')}</Table.HeaderCell>
            <Table.HeaderCell>{t('tableHeader.version')}</Table.HeaderCell>
            <Table.HeaderCell>{t('tableHeader.select')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {components.map((product) => (
          <Fragment key={product.dependencyId}>
            <RequirementComponent
              authorized={authorized}
              sourceDetails={product}
              dependencySelections={dependencySelections}
              setDependencySelections={setDependencySelections}
              versionSelectionInfo={versionSelectionInfo}
              individually={individualSelection}
              dependenciesToCheck={dependenciesToCheck}
              setDepsToCheck={setDepsToCheck}
            />
          </Fragment>
        ))}
      </Table>
      {pages > 1 && (
        <CustomPagination
          loading={loading}
          error={error}
          totalPages={pages}
          activePage={activePageState}
          setActivePage={setActivePageState}
        />
      )}
    </>
  );
};

export default RequirementComponentsList;
