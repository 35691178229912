import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Item } from 'semantic-ui-react';
import { ProductType } from '../../../../../graphql/generated/graphql';
import InfoIconTooltip from '../../../../ui/InfoIconTooltip';

const mainTypes = [
  { key: 'Enterprise', text: 'Enterprise', value: ProductType.Enterprise },
  { key: 'Car', text: 'Car', value: ProductType.Car },
];
const subTypeCar = [
  {
    key: 'Application',
    text: 'Application',
    value: ProductType.Application,
  },
  { key: 'Ecu', text: 'ECU', value: ProductType.ECU },
  {
    key: 'Hardware',
    text: 'Hardware',
    value: ProductType.Hardware,
  },
  {
    key: 'Software',
    text: 'Software',
    value: ProductType.Software,
  },
  { key: 'Other', text: 'Other', value: ProductType.Other },
];
const subTypeEnterprise = [
  {
    key: 'Application',
    text: 'Application',
    value: ProductType.Application,
  },
  {
    key: 'Hardware',
    text: 'Hardware',
    value: ProductType.Hardware,
  },
  { key: 'Other', text: 'Other', value: ProductType.Other },
];

type Props = {
  val: string[];
  setVal: Dispatch<SetStateAction<ProductType[] | undefined>>;
};

/**
 * Dropdown to select the product type. Consists of 2 Selections-Main Type & Subtype
 *
 * @param {Props} props Value and setValue for the Dropdown selections
 * @returns {JSX.Element} TypeDropdown as an Array of 2 values- Important!
 */
const UserInfoBoxTypeDropdown = (props: Props): JSX.Element => {
  const [t] = useTranslation(['productDetails']);

  const { val, setVal } = props;
  // props are coming as an Array=  ["Main project type", "project subtype"]
  // states are directly in parent component.
  return (
    <div style={{ display: 'flex' }}>
      <Item.Content>
        <Item.Meta
          style={{
            fontSize: '14px',
            color: 'rgba(0, 0, 0, 0.6)',
            margin: '7px 0 ',
          }}
        >
          {t('productType')}
          <InfoIconTooltip infoText={t('productType-info')} />
        </Item.Meta>
        <Dropdown
          id="DropdownProductTypeMain"
          fluid
          closeOnChange
          selection
          onChange={(_e, { value }) => {
            setVal([value, ''] as ProductType[]); // needed to secure validation that Subtype Input is selected-disables the Save Details Button
          }}
          value={val[0]}
          options={mainTypes}
        />
      </Item.Content>
      <Item.Content>
        <Item.Meta
          style={{
            fontSize: '14px',
            color: 'rgba(0, 0, 0, 0.6)',
            margin: '7px 0 ',
          }}
        >
          {t('productSubType')}
          <InfoIconTooltip infoText={t('productSubType-info')} />
        </Item.Meta>

        <Dropdown
          id="DropdownProductTypeSubType"
          fluid
          closeOnChange
          disabled={!val[0]}
          selection
          onChange={(_e, { value }) => {
            setVal([val[0], value] as ProductType[]);
          }}
          value={val[1]}
          options={val[0] === ProductType.Car ? subTypeCar : subTypeEnterprise}
        />
      </Item.Content>
    </div>
  );
};

export default UserInfoBoxTypeDropdown;
