import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  DropdownProps,
  Header,
  Message,
  Label,
  Modal,
  Segment,
} from 'semantic-ui-react';

import { ProductDetailsContext } from '../../../../context/UserContext';
import {
  DependencySelection,
  RequirementInfo,
  RequirementType,
  SelectionType,
  useSelectRequirementsMutation,
} from '../../../../graphql/generated/graphql';
import { GET_REQUIREMENTS } from '../../../../graphql/queries/RequirementQuerys';
import {
  LEGAL_CHECK_DONE_POSSIBLE,
  LEGAL_CHECK_POSSIBLE,
  SUPPLIER_INPUT_DONE_POSSIBLE,
} from '../../../../graphql/queries/VersionQuerys';
import AutoExpandingTextarea from '../../../licenses/AutoExpandingTextarea';
import RequirementComponentsList from './RequirementComponentsList';
import { useSelectionTypeOpts } from './helper/Constants';

type Props = {
  requirementInfo: RequirementInfo;
  closeModal: () => void;
};

/**
 *@returns {JSX.Element} Modal Tab for Questions-preparation for coming Ticket and its implementation
 *@param {Props} props { requirementInfo }
 */
const RequirementsTab = (props: Props): JSX.Element => {
  const { i18n, t } = useTranslation(['requirements', 'buttons']);
  const { requirementInfo, closeModal } = props;
  const initialVersionType = requirementInfo?.selectionInfo || null; // version selection type

  const [versionSelectionInfo, setVersionSelectionInfo] =
    useState<SelectionType | null>(initialVersionType);
  const [dependencySelections, setDependencySelections] = useState<
    DependencySelection[]
  >([]);
  const [dependenciesToCheck, setDepsToCheck] = useState<DependencySelection[]>(
    []
  );
  const [globallySelected, setGloballySelected] = useState(false);
  const [individually, setIndividually] = useState(
    requirementInfo.individuallySelected
  );
  const [showList, setShowList] = useState(false);
  const description =
    i18n.language === 'de'
      ? requirementInfo?.requirement.description_de
      : requirementInfo?.requirement.description_en;

  const { versionId, authorizations, processStatusAsString } = useContext(
    ProductDetailsContext
  );
  const refetch = () => {
    if (processStatusAsString === 'LEGALCHECK') {
      return [GET_REQUIREMENTS, LEGAL_CHECK_DONE_POSSIBLE];
    }
    if (processStatusAsString === 'SUPPLIERINPUT') {
      return [
        GET_REQUIREMENTS,
        SUPPLIER_INPUT_DONE_POSSIBLE,
        LEGAL_CHECK_POSSIBLE,
      ];
    }
    return [GET_REQUIREMENTS, LEGAL_CHECK_POSSIBLE];
  };

  const authorized =
    (requirementInfo?.requirement.type === RequirementType.user &&
      authorizations?.includes('SelectUserRequirements')) ||
    (requirementInfo?.requirement.type === RequirementType.legal &&
      authorizations?.includes('SelectLegalRequirements'));

  const [selectRequirements] = useSelectRequirementsMutation({
    variables: {
      versionId,
      requirementId: requirementInfo.requirement.id,
      selectionType: globallySelected ? versionSelectionInfo : null,
      dependencySelections: individually ? dependencySelections : [], // ensures that empty array is sent when all dependencies are equal
    },
    refetchQueries: refetch,
  });
  // TODO fix this !
  const changedSelection =
    versionSelectionInfo !== initialVersionType ||
    globallySelected ||
    dependencySelections.length > 0;

  // dropdown options with "nicht wählbarer Zustand" if individuall selection is true
  const dropdownValue = individually
    ? 'individuall'
    : versionSelectionInfo || initialVersionType || undefined;

  const handleSaveChanges = () => {
    selectRequirements();
    closeModal();
  };
  // checking if the dependencies are equal the same selectionType to  version/ requirement
  useEffect(() => {
    if (dependencySelections.length > 0) {
      const areAllPropertiesEqual = () => {
        const firstObject = dependenciesToCheck[0];

        return dependenciesToCheck.every((obj) => {
          return obj.selectionType === firstObject.selectionType;
        });
      };
      if (dependenciesToCheck.length > 0 && areAllPropertiesEqual()) {
        setIndividually(false);
        setGloballySelected(true);
        setVersionSelectionInfo(dependenciesToCheck[0].selectionType || null);
      }
      if (dependenciesToCheck.length > 0 && !areAllPropertiesEqual()) {
        setIndividually(true);
      }
    }
  }, [
    versionSelectionInfo,
    dependenciesToCheck,
    setVersionSelectionInfo,
    dependencySelections,
  ]);

  return (
    <>
      <Modal.Content id="RequirementsTabContent">
        <Segment.Group>
          <Segment
            id="RequirementsDescriptionSegment"
            className="text-area-segment"
          >
            <AutoExpandingTextarea content={description} maxRows={15} />
          </Segment>
          {!authorized && (
            <Message
              id="RequirementsTabWarning"
              warning
              icon="exclamation triangle"
              content={t('readOnly')}
            />
          )}
          <Segment
            id="RequirementsTabAnswerSelection"
            attached={authorized ? undefined : 'bottom'}
          >
            <Header content={t('selectAnswer')} />
            <span>
              <span>
                {t('selectForAllLeftPart')}
                &nbsp;
                <Label
                  id="RequirementsDependencyCount"
                  color="grey"
                  content={requirementInfo?.dependencyCount}
                />
                &nbsp;
                {t('selectForAllRightPart')}
              </span>
              <Dropdown
                id="RequirementsTabVersionSelect"
                options={useSelectionTypeOpts()}
                pointing="top"
                value={dropdownValue}
                disabled={!authorized}
                inline
                placeholder={t('selectionBar')}
                onChange={(e, data: DropdownProps) => {
                  setVersionSelectionInfo(data.value as SelectionType);
                  setIndividually(false);
                  setGloballySelected(true);
                  setDepsToCheck([
                    ...dependenciesToCheck.map((d) => {
                      return {
                        dependencyId: d.dependencyId,
                        selectionType: data.value as SelectionType,
                      };
                    }),
                  ]);
                }}
                closeOnChange
              />
            </span>
            <Divider />
            <Checkbox
              id="ToggleCheckbox"
              toggle
              checked={showList}
              onChange={() => setShowList(!showList)}
              label={t(`showProducts`)}
            />
            {showList && requirementInfo && (
              <RequirementComponentsList
                authorized={authorized}
                requirementId={requirementInfo.requirement.id}
                dependencySelections={dependencySelections}
                setDependencySelections={setDependencySelections}
                versionSelectionInfo={versionSelectionInfo}
                individualSelection={individually}
                setDepsToCheck={setDepsToCheck}
                dependenciesToCheck={dependenciesToCheck}
              />
            )}
          </Segment>
        </Segment.Group>
      </Modal.Content>
      <Modal.Actions>
        <Button
          id="RequirementsTabSaveChanges"
          primary
          disabled={!authorized || !changedSelection}
          content={t('saveChanges', { ns: 'buttons' })}
          onClick={handleSaveChanges}
        />
      </Modal.Actions>
    </>
  );
};

export default RequirementsTab;
