import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Label, Menu, Message, Segment } from 'semantic-ui-react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GET_TODOS } from '../../../../graphql/queries/ToDoQueries';
import GenericClosedSegmentSkeleton from '../skeletons/GenericClosedSegmentSkeleton';
import ToDosList from './ToDoList';
import { Role, ToDoSelectionInfo } from '../../../../graphql/generated/graphql';
import {
  ProductDetailsContext,
  SelectedRoleContext,
} from '../../../../context/UserContext';

/**
 * View for ProductToDos
 *
 *@returns {JSX.Element} The static accordion header-component for To-Dos list in a chosen product.
 */
const ToDosViewHeader = (): JSX.Element => {
  const [t] = useTranslation(['todos']);
  const [open, setOpen] = useState(true);
  const { id: versionId } = useParams();
  const { authorizations } = useContext(ProductDetailsContext);
  const { selectedRole } = useContext(SelectedRoleContext);
  const role: Role = selectedRole;

  const { loading, data, error } = useQuery(GET_TODOS, {
    variables: {
      versionId,
    },
  });

  if (loading) {
    return (
      <GenericClosedSegmentSkeleton
        height={105}
        image
        lines={1}
        labels="all"
        linesLength="short"
      />
    );
  }

  const hasPermissionToSee = authorizations?.includes('GetToDos');
  const hasPermissionToSelect =
    authorizations?.includes('SelectToDo') &&
    (role?.includes('developer') || role?.includes('supplier'));

  if (error) {
    if (hasPermissionToSee) {
      return (
        <Message
          id="TodosViewHeaderError"
          error
          icon="times circle"
          header="Error"
          content={`Error ${error.message}`}
        />
      );
    }
  }
  const todos = hasPermissionToSee ? data?.GetToDos : [];

  // check for parents by checking for parentID
  const parentTodos = todos.filter((td: ToDoSelectionInfo) => !td.parentToDoId);

  const selected = parentTodos?.filter(
    (toDo: { selectionType: string }) =>
      toDo.selectionType === 'userselected' ||
      toDo.selectionType === 'autoselected'
  );

  const { length: parentLength = 0 } = parentTodos ?? {};
  const { length: selectedLength = 0 } = selected ?? {};
  const allSelected = parentLength === selectedLength;

  const openedClosedToDosText = allSelected
    ? t('allDone')
    : `${parentLength - selectedLength} ${t(
        `opentoDos.${parentLength - selectedLength === 1}`
      )}!`;

  return (
    <Segment raised id="EditableToDosViewHeaderContainerSegment">
      <Menu id="ToDosView.Menu-Tabs" pointing secondary compact>
        <Menu.Item>
          <Icon
            id="EditableToDosViewHeaderIcon"
            onClick={() => setOpen(!open)}
            name={open ? 'chevron up' : 'chevron down'}
            color={open ? 'blue' : 'black'}
          />
        </Menu.Item>
        <Menu.Item
          name="To-Dos"
          active={open}
          onClick={() => setOpen(!open)}
          color="blue"
        >
          {t('toDos')}{' '}
          <Label
            style={{ marginLeft: '1em' }}
            id="EditableToDosViewHeaderLabel"
            circular
          >
            {parentTodos.length}
          </Label>
        </Menu.Item>
      </Menu>
      <div style={{ display: 'inline', marginLeft: '45px' }}>
        {parentLength !== selectedLength && (
          <Message
            id="TodosViewHeaderMsgCount"
            info
            compact
            icon="info circle"
            content={openedClosedToDosText}
          />
        )}
        {parentLength === selectedLength && parentLength !== 0 && (
          <Message
            id="TodosViewHeaderMsgCountClosed"
            success
            compact
            icon="check circle"
            content={openedClosedToDosText}
          />
        )}
      </div>
      {/* TO-DOS listing Component */}
      {open && (
        <>
          {!hasPermissionToSee && (
            <Message
              id="TodosViewHeaderMsgNotAuthorizedGetToDo"
              warning
              icon="exclamation triangle"
              content={t('wrongState')}
            />
          )}
          {!hasPermissionToSelect && hasPermissionToSee && (
            <Message
              id="TodosViewHeaderMsgNotAuthorizedSelectToDo"
              warning
              icon="exclamation triangle"
              content={t('readOnly')}
            />
          )}
          {hasPermissionToSee && (
            <div
              id="ToDosListContainer"
              className={hasPermissionToSelect ? '' : 'read_only'}
            >
              <ToDosList
                todos={todos}
                versionId={versionId}
                parentTodos={parentTodos}
                allSelected={allSelected}
              />
            </div>
          )}
        </>
      )}
    </Segment>
  );
};

export default ToDosViewHeader;
