import React, { useContext, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client/react/hooks';
import { Maybe } from '../../../../../../../graphql/generated/graphql';
import {
  DEPENDENCY_BY_ID,
  DEPENDENCY_TREE,
  DEPENDENCY_TREE_AMOUNT,
} from '../../../../../../../graphql/queries/DependencyQuerys';
import DependencyTreeModal from '../../fossDependencyList/listComponents/columnComponents/DependencyTreeElement/DependencyTreeModal';
import LinkedToPopup from './LicenseModalComponents/LinkedToPopup';
import { ProductDetailsContext } from '../../../../../../../context/UserContext';

type PropsType = {
  sourceDependencyIds: Maybe<Array<Maybe<string>>> | undefined;
  dependencyId: Maybe<string> | undefined;
  onComponentClick?: (name: string) => void;
};

/**
 * element for displaying the name of the parent dependency
 *
 * @param {PropsType} props id to source dependency
 * @returns {JSX.Element} element containing the name of the parent dependency
 */
const LinkedToElement = (props: PropsType): JSX.Element => {
  const { sourceDependencyIds, dependencyId, onComponentClick } = props;
  const { versionId } = useContext(ProductDetailsContext);
  const [clickedSourceDependencyId, setClickedSourceDependencyId] =
    useState<string>('');
  const [openDependencyTreeModalOpen, setOpenDependencyTreeModal] =
    useState(false);
  const [dependencyCount, setDependencyCount] = useState(0);

  // TODO: This is called once for every dependency
  const { data } = useQuery(DEPENDENCY_BY_ID, {
    skip: !sourceDependencyIds,
    variables: {
      where: {
        id_IN: sourceDependencyIds,
      },
    },
  });

  const [
    fetchDependencyTree,
    { data: depTreeData, loading: depTreeLoading, refetch: refetchDepTree },
  ] = useLazyQuery(DEPENDENCY_TREE);

  const [
    fetchDependencyTreeAmount,
    {
      data: depTreeAmountData,
      loading: depTreeAmountLoading,
      error: depTreeAmountError,
    },
  ] = useLazyQuery(DEPENDENCY_TREE_AMOUNT);

  const firstSourceDep = data?.Dependency?.[0];
  const additionalSourceDeps = data?.Dependency?.slice(1);

  const handleDependencyClick = (id: string) => {
    fetchDependencyTree({
      variables: {
        versionId,
        dependencyId,
        sourceDependencyId: id,
        offset: 0,
      },
      onCompleted: (res) => {
        setDependencyCount(res.DependencyTree.length - 1);
      },
    });
    fetchDependencyTreeAmount({
      variables: {
        versionId,
        dependencyId,
        sourceDependencyId: id,
      },
    });
    setClickedSourceDependencyId(id);
    setOpenDependencyTreeModal(true);
  };

  return (
    <>
      {depTreeData &&
        depTreeAmountData &&
        depTreeData.DependencyTree &&
        !depTreeLoading &&
        !depTreeAmountLoading && (
          <DependencyTreeModal
            dependencyId={dependencyId}
            sourceDepId={clickedSourceDependencyId}
            depTreeData={depTreeData.DependencyTree}
            depTreeLoading={depTreeLoading}
            depTreeAmountData={depTreeAmountData.DependencyTreeAmount}
            depTreeAmountLoading={depTreeAmountLoading}
            depTreeAmountError={depTreeAmountError}
            open={openDependencyTreeModalOpen}
            setOpen={setOpenDependencyTreeModal}
            onComponentClick={onComponentClick}
            refetchDepTree={refetchDepTree}
            dependencyCount={dependencyCount}
            setDependencyCount={setDependencyCount}
          />
        )}
      {data?.Dependency && (
        <>
          <button
            id="DependencyTreeModalButton"
            key={firstSourceDep.id}
            type="button"
            className="clickableSourceDep"
            onClick={() => {
              handleDependencyClick(firstSourceDep.id);
            }}
          >
            {firstSourceDep.componentName}
          </button>
          {additionalSourceDeps && additionalSourceDeps.length > 0 ? (
            <LinkedToPopup
              dependencyId={dependencyId}
              sourceDeps={additionalSourceDeps}
              handleDependencyClick={handleDependencyClick}
            />
          ) : null}
        </>
      )}
    </>
  );
};

export default LinkedToElement;
