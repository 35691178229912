import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Header,
  Icon,
  List,
  Modal,
  Segment,
} from 'semantic-ui-react';
import {
  DependencySubComponentsRelationship,
  Maybe,
  Result,
} from '../../../../../../../../graphql/generated/graphql';
import { ProductDetailsContext } from '../../../../../../../../context/UserContext';
import useLegalResultQueries from './helper/useLegalResultQueries';
import { ResultNode } from './helper/types';
import LegalResultModalStatusForm from './LegalResultModalStatusForm';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  id: string;
  name: string;
  currentStatus: string;
  resultNode: ResultNode;
  legalResult?: Maybe<Result> | undefined;
  licenseTextEdges?: Array<DependencySubComponentsRelationship>;
};

/**
 * Modal for changing the legal result of either a version or a dependency
 *
 * @returns {JSX.Element} Element
 * @param {Props} props Props
 */
const LegalResultModal = (props: Props): JSX.Element => {
  const {
    open,
    setOpen,
    name,
    currentStatus = 'UNKNOWN',
    id: featureId,
    resultNode,
    legalResult,
    licenseTextEdges = [],
  } = props;
  const [t] = useTranslation('productDetails');
  const { versionId } = useContext(ProductDetailsContext);
  const actualNote = legalResult?.note || '';
  const [newStatus, setNewStatus] = useState('');
  const [newNote, setNewNote] = useState('');
  const [ignoreUnverifiedLicenses, setIgnoreUnverifiedLicenses] =
    useState(false);

  const { updateResult } = useLegalResultQueries();

  const resetAndClose = () => {
    setOpen(false);
    setNewStatus('');
    setNewNote('');
    setIgnoreUnverifiedLicenses(false);
  };

  const unverifiedLicenses = licenseTextEdges
    .filter((edge) => edge.verified === false && edge.selected === true)
    .map((edge) => edge.spdxId)
    .filter((value, index, self) => self.indexOf(value) === index);

  const unverifiedLicensesWarning =
    unverifiedLicenses.length !== 0 && newStatus === 'OK';
  const disabled =
    newStatus.length < 1 ||
    (unverifiedLicensesWarning && !ignoreUnverifiedLicenses);

  const handleStatusUpdate = () => {
    if (!disabled) {
      updateResult(
        {
          versionId,
          featureId,
          status: newStatus,
          note: newNote,
        },
        resultNode
      );
      resetAndClose();
    }
  };

  useEffect(() => {
    if (open) {
      setNewNote(actualNote);
    }
  }, [actualNote, open]);

  return (
    <Modal
      size="tiny"
      id="LegalResultModal"
      open={open === true}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
    >
      <Modal.Header className="LegalResultModal">
        {t('legalResultModal.legalCheckTitle')}
        <Icon
          id="LegalResultModalCloseButton"
          name="close"
          link
          onClick={resetAndClose}
        />
      </Modal.Header>
      <Modal.Content>
        <Modal.Description
          className="LegalResultModalDescription"
          content={t('legalResultModal.legalCheckNotice', { name })}
        />
        <LegalResultModalStatusForm
          setNewStatus={setNewStatus}
          setNewNote={setNewNote}
          newNote={newNote}
          currentStatus={currentStatus}
          newStatus={newStatus}
        />
        {/* UNVERIFIED LICENSES WARNING */}
        {unverifiedLicensesWarning && (
          <Segment id="UnverifiedLicensesWarning">
            <Header
              as="h4"
              content={t('legalResultModal.warningHeader', {
                count: unverifiedLicenses.length,
                licenseName: unverifiedLicenses[0],
                interpolation: {
                  escapeValue: false,
                },
              })}
            />
            {unverifiedLicenses.length > 1 && (
              <List id="UnverifiedLicensesList" bulleted size="mini">
                <List.Item>{unverifiedLicenses.slice(1).join(', ')}</List.Item>
              </List>
            )}
            <div className="centered-checkbox">
              <Checkbox
                id="IgnoreUnverifiedLicensesCheckbox"
                checked={ignoreUnverifiedLicenses}
                onClick={() =>
                  setIgnoreUnverifiedLicenses(!ignoreUnverifiedLicenses)
                }
              />
              {t('legalResultModal.warningCheckbox')}
            </div>
          </Segment>
        )}
      </Modal.Content>
      <Modal.Actions className="LegalResultModalActions">
        <Button
          id="LegalResultModalConfirmStatus"
          disabled={disabled}
          primary={!disabled}
          secondary={disabled}
          content={t('legalResultModal.statusTaken')}
          onClick={handleStatusUpdate}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default LegalResultModal;
