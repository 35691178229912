import React from 'react';
import { DirectoryRole, Role, User } from '../graphql/generated/graphql';

/**
 * UserContext
 * provides the User information of the currently logged in User,
 * via the useContext hook.
 */
export const UserContext = React.createContext<User>({} as User);

/**
 * ProductDetailsContext
 * provides the ProductDetails of the currently selected Product Version
 */
export const ProductDetailsContext = React.createContext<{
  versionId: string;
  productId: string;
  isProject: boolean;
  finishedImport: boolean;
  accessRoles: DirectoryRole[];
  authorizations: string[];
  processStatusAsString: string;
  tags: string[];
  inLegalCheckOrDone: boolean;
  isGroupTransfer: boolean;
}>({
  versionId: '',
  productId: '',
  isProject: false,
  finishedImport: false,
  accessRoles: [],
  authorizations: [],
  processStatusAsString: 'INCREATION',
  tags: [],
  inLegalCheckOrDone: false,
  isGroupTransfer: false,
});

/**
 * SelectedRoleContext
 * provides the Role currently selected by the User and a function to
 * to change the selected Role, via the useContext hook.
 */
export const SelectedRoleContext = React.createContext<{
  selectedRole: Role;
  setSelectedRole: (param: Role) => void;
}>({
  selectedRole: '' as Role,
  setSelectedRole: () => '',
});

/**
 * Authorizations context that is queried with CurrentUser to get to the highest level in tool
 *
 */
export const AuthorizationsContext = React.createContext<string[]>([]);
