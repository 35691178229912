import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Item } from 'semantic-ui-react';
import StandardSoftwareModal from './StandardSoftwareModal';
import InfoIconTooltip from '../../../../ui/InfoIconTooltip';

interface Props {
  standardSoftware: boolean | undefined;
  setStandardSoftware?: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
  editing?: boolean;
}

/**
 * @param {boolean} props {standardSoftware, setStandardSoftware, editing}
 * @returns {JSX.Element}  Standard Software Component for Info Box
 */
const StandardSoftwareItem = (props: Props): JSX.Element => {
  const { t } = useTranslation('productDetails');
  const { editing = false, standardSoftware, setStandardSoftware } = props;
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      {setStandardSoftware && (
        <StandardSoftwareModal
          open={openModal}
          setOpen={setOpenModal}
          standardSoftware={standardSoftware}
          setStandardSoftware={setStandardSoftware}
        />
      )}
      <Item id="standardSoftwareContent">
        <Item.Content>
          <Item.Meta>
            {/* ICON INFO IN CIRCLE */}

            {t('softwareType')}
            <InfoIconTooltip infoText={t('softwareType-info')} />
            {editing && (
              <Item.Extra
                className="edit-link"
                onClick={() => setOpenModal(true)}
              >
                {t('editStandardSoftware', { ns: 'buttons' })}
              </Item.Extra>
            )}
          </Item.Meta>
          <Item.Description style={{ color: 'black', fontWeight: 'bold' }}>
            {t(standardSoftware ? 'standardSoftware' : 'individualSoftware')}
          </Item.Description>
        </Item.Content>
      </Item>
    </>
  );
};

export default StandardSoftwareItem;
