import { DocumentNode } from 'graphql';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  DropdownItemProps,
  Feed,
  Icon,
  SemanticICONS,
} from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../context/UserContext';
import { Contact, Department } from './helper/types';
import UpdateReviewerModal from './UpdateReviewerModal';

type Props = {
  contact: Contact | null | undefined;
  department: Department;
  mutation: DocumentNode;
  options: DropdownItemProps[] | undefined;
  icon: JSX.Element | SemanticICONS;
};

/**
 * @param {object} props Name, email, team, department, mutation, icon, readOnly
 * @returns {JSX.Element} ReviewerCard.
 */
const ReviewerCard = (props: Props): JSX.Element => {
  const { contact, department, mutation, options, icon } = props;
  const { name, email } = contact || {};
  const [t] = useTranslation(['users']);

  const { authorizations } = useContext(ProductDetailsContext);

  const authorized = () => {
    if (department === Department.OSO) {
      return authorizations?.includes('UpdateOsoReviewer');
    }
    if (department === Department.LEGAL) {
      return authorizations?.includes('UpdateLegalReviewer');
    }
    return true;
  };

  return (
    <Card id={`ReviewerCard.${department}`} className="reviewer" fluid>
      <Card.Content>
        <Feed>
          <Feed.Event>
            <Feed.Label>
              {typeof icon === 'string' ? (
                <Icon size="large" name={icon} id="UserIcon" />
              ) : (
                icon
              )}
            </Feed.Label>
            {name ? (
              <Feed.Content>
                <Feed.Summary className="inline">
                  {name}
                  {authorized() && (
                    <UpdateReviewerModal
                      department={department}
                      mutation={mutation}
                      options={options}
                      reviewer={contact}
                      trigger={
                        <Button
                          id="EditButton"
                          className="borderless fit-content height"
                          icon="pencil"
                          basic
                          color="blue"
                          floated="right"
                        />
                      }
                    />
                  )}
                </Feed.Summary>
                {!contact && (
                  <UpdateReviewerModal
                    department={department}
                    mutation={mutation}
                    options={options}
                    reviewer={contact}
                  />
                )}
                <Feed.Extra content={t(`department.${department}`)} />
                <span
                  className="email"
                  style={
                    email ? { visibility: 'visible' } : { visibility: 'hidden' }
                  }
                >
                  <Icon name="mail outline" id="EmailIcon" />
                  {email}
                </span>
              </Feed.Content>
            ) : (
              <Feed.Content>
                <Feed.Extra content={t(`department.${department}`)} />
                {authorized() ? (
                  <UpdateReviewerModal
                    department={department}
                    mutation={mutation}
                    options={options}
                    reviewer={contact}
                    trigger={
                      <Button
                        id="AddContact.Button"
                        fluid
                        content={t('add', {
                          department: t(`department.${department}`),
                        })}
                        icon="pencil"
                        color="blue"
                        basic
                      />
                    }
                  />
                ) : (
                  <Feed.Summary className="inline">
                    {t('department.none-assigned')}
                  </Feed.Summary>
                )}
              </Feed.Content>
            )}
          </Feed.Event>
        </Feed>
      </Card.Content>
    </Card>
  );
};

export default ReviewerCard;
