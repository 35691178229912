/* eslint-disable no-console */
import { ApolloClient, ApolloProvider, from } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import React from 'react';

type PropTypes = {
  children: JSX.Element;
};

/**
 * Creates the Apollo Client to connect to the Backend.
 *
 * @param {PropTypes} props children wrapped inside this JSX.Element.
 * @returns {JSX.Element} Apollo Provier.
 */
const createApolloClient = (props: PropTypes): JSX.Element => {
  const token = localStorage.getItem('token');
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const { hostname = 'localhost' } = window.location;
  let uri = 'http://localhost:4000/graphql';
  if (hostname !== 'localhost') {
    uri = `https://${hostname}/backend`;
  }

  const httpLink = createUploadLink({
    uri,
    headers: {
      'Apollo-Require-Preflight': 'true',
    },
  });

  const linkError = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ extensions, message, locations, path }) => {
        if (extensions) {
          switch (extensions.code) {
            case 'UNAUTHENTICATED':
              if (!(Array.isArray(path) && path.includes('CurrentUser'))) {
                const tokenAvailable = localStorage.getItem('token');
                localStorage.clear();
                if (window.location.pathname !== '/login') {
                  localStorage.setItem('lastUrl', window.location.pathname);
                  if (tokenAvailable) localStorage.setItem('expired', 'true');
                  window.location.assign('/login');
                }
              }
              break;
            case 'DATABASE_NOT_REACHABLE':
              if (!(Array.isArray(path) && path.includes('CurrentUser'))) {
                const tokenAvailable = localStorage.getItem('token');
                localStorage.clear();
                if (window.location.pathname !== '/login') {
                  localStorage.setItem('lastUrl', window.location.pathname);
                  if (tokenAvailable) localStorage.setItem('expired', 'true');
                  window.location.assign('/login');
                }
              }
              break;
            case 'ACTIVATION_PENDING':
              break;
            default:
              break;
          }
        }
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
      });
    }

    if (networkError && !graphQLErrors) {
      console.error(`[Network error]: ${networkError}`);
    }
  });

  const apolloClient = new ApolloClient({
    link: from([authLink, linkError, httpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            GetRequirements: {
              merge(_, incoming = []) {
                return [...incoming];
              },
            },
          },
        },
      },
    }),
  });

  const { children } = props;

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default createApolloClient;
