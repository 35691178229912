import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Item } from 'semantic-ui-react';
import HighPriorityRequestModal from './HighPriorityRequestModal';
import InfoIconTooltip from '../../../../ui/InfoIconTooltip';

interface Props {
  editing?: boolean;
  highPriority: boolean | undefined;
  setHighPriority: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

/**
 * @param {object} props {editing?, highPriority, setHighPriority}
 * @returns {JSX.Element}  High priority request Component for Info Box
 */
const HighPriorityRequestItem = (props: Props): JSX.Element => {
  const { t } = useTranslation(['productDetails', 'buttons']);
  const { editing = false, highPriority, setHighPriority } = props;
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <HighPriorityRequestModal
        open={openModal}
        setOpen={setOpenModal}
        highPriority={highPriority}
        setHighPriority={setHighPriority}
      />
      <Item id="HighPriorityRequestContent">
        <Item.Content>
          <Item.Meta>
            {/* ICON INFO IN CIRCLE */}

            {t('highPriority')}
            <InfoIconTooltip infoText={t('highPriorityInfo')} />
            {editing && (
              <Item.Extra
                className="edit-link"
                onClick={() => setOpenModal(true)}
              >
                {t('editHighPriority', { ns: 'buttons' })}
              </Item.Extra>
            )}
          </Item.Meta>
          {highPriority === undefined ? (
            <Item.Description style={{ color: 'black' }}>
              {t('enterHighPriorityMessage')}
            </Item.Description>
          ) : (
            <Item.Description style={{ color: 'black', fontWeight: 'bold' }}>
              {highPriority ? (
                <Icon
                  id="HighPriorityRequestIconHigh"
                  name="angle double up"
                  color="red"
                />
              ) : (
                <Icon
                  id="HighPriorityRequestIconNotHigh"
                  name="angle double down"
                  color="blue"
                />
              )}
              <span>{t(`highPriority.${highPriority}`)}</span>
            </Item.Description>
          )}
        </Item.Content>
      </Item>
    </>
  );
};

export default HighPriorityRequestItem;
