import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Icon, Message } from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../../../../../context/UserContext';
import {
  DependencySubComponentsRelationship,
  useVerifyLicenseMutation,
} from '../../../../../../../../graphql/generated/graphql';

type LicenseElementVerifyProps = {
  subComponentEdge: DependencySubComponentsRelationship;
  dependencyId: string;
  subcomponentId: string;
};

/**
 *  The element shows the queried value! Toggle fires mutation on click
 *
 *@returns {JSX.Element} License verified toggle Button
 *@param {LicenseElementVerifyProps} props Props
 */
const LicenseElementVerificationUpdate = (
  props: LicenseElementVerifyProps
): JSX.Element => {
  const [text] = useTranslation('productDetails');
  const { dependencyId, subcomponentId, subComponentEdge } = props;
  const { authorizations } = useContext(ProductDetailsContext);

  const [VerifyLicense, { loading, error }] = useVerifyLicenseMutation({
    refetchQueries: ['DependenciesByVersionId'],
  });

  const handleVerification = () => {
    VerifyLicense({
      variables: {
        dependencyId,
        subcomponentId,
        verified: !subComponentEdge?.verified,
        licenseTextVerificationChecksum:
          subComponentEdge?.licenseTextVerificationChecksum,
      },
    });
  };

  return (
    <span>
      <Checkbox
        id="LicensesElementModalToggleButton"
        disabled={loading || !authorizations.includes('VerifyLicense')}
        toggle
        checked={subComponentEdge?.verified}
        onChange={handleVerification}
        label={text(
          `dependencyList.licenseModal.verifiedToggleButton.${subComponentEdge?.verified}`
        )}
      />{' '}
      {loading && (
        <Icon
          id="LicensesElementModalVerificationSpinner"
          loading
          name="spinner"
        />
      )}
      {error && (
        <Message
          id="LicensesElementModalToggleButtonError"
          error
          icon="times circle"
          content={`Error ${error.message}`}
        />
      )}
    </span>
  );
};

export default LicenseElementVerificationUpdate;
