import { useApolloClient } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Menu, Popup, Segment } from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../context/UserContext';
import {
  useDependenciesByVersionIdAmountQuery,
  useRunningImportLazyQuery,
} from '../../../../graphql/generated/graphql';
import DependencyList from './dependencyList/fossDependencyList/DependencyList';
import DependencyImportModal from './dependencyList/fossDependencyList/listComponents/DependencyImportModal';
import PVDependencyList from './dependencyList/productVersionDependencyList/PVDependencyList';
import EditProductPartsModal from './editProductParts/EditProductPartsModal';

type Props = {
  show: boolean;
};

/**
 * Collapsible segment containing the dependency list
 *
 * @param {object} props {show: boolean}
 * @returns {JSX.Element} Collapsible segment containing the dependency list
 */
const ProductDependencySegment = (props: Props): JSX.Element => {
  const { show = true } = props;
  const [text, i18n] = useTranslation('productDetails');
  const client = useApolloClient();
  const i18nLang = i18n.language;
  const [open, setOpen] = useState(show);
  const [openModal, setOpenModal] = useState(false);
  const {
    versionId,
    isProject,
    authorizations,
    processStatusAsString,
    finishedImport,
  } = useContext(ProductDetailsContext);

  const {
    data: dataAmount,
    error: errorAmount,
    loading: loadingAmount,
    refetch: refetchAmount,
  } = useDependenciesByVersionIdAmountQuery({
    variables: { versionId },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const [, runningImport] = useRunningImportLazyQuery({
    variables: { id: versionId },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: async (data) => {
      if (data.Version?.finishedImport) {
        runningImport.stopPolling();
        client.refetchQueries({
          include: [
            'DependenciesByVersionId',
            'DependenciesByVersionIdAmount',
            'ProductDetailsContext',
            'LegalCheckPossible',
          ],
        });
      }
    },
  });

  const notAuthorizedForImport = !authorizations.includes('JsonImport');

  const dependenciesAmount: number =
    dataAmount?.DependenciesByVersionIdAmount || 0;

  const importDisabled =
    finishedImport || !authorizations.includes('JsonImport');

  const importPopupMessage = () => {
    if (notAuthorizedForImport) {
      return text('dependencyList.importModal.noAuthorized');
    }
    if (finishedImport) {
      return text('dependencyList.importModal.alreadyImported');
    }
    return text('dependencyList.importModal.runningImport');
  };
  React.useEffect(() => {
    if (!importDisabled) {
      runningImport.startPolling(30000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Segment raised id={`DependencySegment${versionId}`}>
      <Menu id="ProductDependencyMenuTab" secondary compact>
        <Menu.Item>
          <Icon
            id="ProductDependencyAccordion"
            name={open ? 'chevron up' : 'chevron down'}
            color={open ? 'blue' : 'black'}
            onClick={() =>
              setOpen((prevOpen) => {
                return !prevOpen;
              })
            }
          />
        </Menu.Item>
        <Menu.Item className="ViewSegmentsMenuTabTitleItem">
          {text('dependencyList.productDependencyList')}
          <span>
            <Icon
              size="large"
              name="question circle"
              id={`ProductDependencyGuideIcon-${i18nLang}-${
                isProject ? 'fossDep' : 'pvDep'
              }`}
              style={{ marginLeft: '0.5rem', display: 'none' }} // TODO: remove display none when guide is ready
            />
          </span>
        </Menu.Item>
        {isProject ? (
          <Popup
            id="Popup"
            disabled={authorizations.includes('UpdateProductParts')}
            content={text(
              `dependencyList.editPartsModal.cannotUpdate.${
                processStatusAsString === 'COMPLETED'
              }` // if not completed than shows not authorised msg
            )}
            trigger={
              <div className="ProductDependencyMenuModals">
                <Menu.Item>
                  <EditProductPartsModal
                    projectVersionId={versionId}
                    updatePartsEnabled={authorizations.includes(
                      'UpdateProductParts'
                    )}
                  />
                </Menu.Item>
              </div>
            }
          />
        ) : (
          <div className="ProductDependencyMenuModals">
            <Menu.Item>
              <Popup
                id="ImporterModalPopupOnButtonDisabled"
                content={importPopupMessage()}
                disabled={
                  !importDisabled && !runningImport.data?.Version?.runningImport
                }
                inverted
                hoverable
                wide
                on="hover"
                trigger={
                  <span>
                    <Button
                      id="ImporterModal.Button.Open"
                      primary
                      basic
                      content={
                        runningImport.data?.Version?.runningImport ? (
                          <>
                            <Icon id="Spinner" loading name="spinner" />
                            {text('dependencyList.importModal.button.open')}
                          </>
                        ) : (
                          text('dependencyList.importModal.button.open')
                        )
                      }
                      disabled={importDisabled}
                      onClick={() => {
                        setOpenModal(true);
                      }}
                    />
                  </span>
                }
              />

              {openModal && (
                <DependencyImportModal
                  open={openModal}
                  setOpen={setOpenModal}
                  refetchRunningImport={runningImport.refetch}
                />
              )}
            </Menu.Item>
          </div>
        )}
      </Menu>
      {open &&
        (isProject ? (
          <PVDependencyList />
        ) : (
          <DependencyList
            dependenciesAmount={dependenciesAmount}
            errorAmount={errorAmount}
            loadingAmount={loadingAmount}
            refetchAmount={refetchAmount}
          />
        ))}
    </Segment>
  );
};

export default ProductDependencySegment;
