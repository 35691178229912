import { useApolloClient, useMutation } from '@apollo/client';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Grid,
  Header,
  Icon,
  Loader,
  Popup,
  Segment,
} from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../context/UserContext';
import {
  useCheckResolverStatusLazyQuery,
  useVersionResultsQuery,
} from '../../../../graphql/generated/graphql';
import {
  CANCEL_RESOLVER,
  RESOLVE_VERSION,
} from '../../../../graphql/mutations/ResultMutations';
import { GET_REQUIREMENTS } from '../../../../graphql/queries/RequirementQuerys';
import { GET_TODOS } from '../../../../graphql/queries/ToDoQueries';
import {
  COMPLETION_POSSIBLE,
  LEGAL_CHECK_DONE_POSSIBLE,
  LEGAL_CHECK_POSSIBLE,
  SUPPLIER_INPUT_DONE_POSSIBLE,
  VERSION_RESULTS as VERSION_RESULT,
} from '../../../../graphql/queries/VersionQuerys';
import i18n from '../../../../i18n/i18n';
import { toLocalDateAndTime } from '../helpers/logics';
import { ResolverStatus } from './dependencyList/helper/types';

type PropsType = {
  refetch: () => void;
};

/**
 * @param {PropsType} props refetch function
 * @returns {JSX.Element} Resolver status and buttons to start/cancel resolver.
 */
const ResolverSegment = (props: PropsType): JSX.Element => {
  const { refetch } = props;
  const [t] = useTranslation('productDetails');
  const { versionId, authorizations, isProject, finishedImport } = useContext(
    ProductDetailsContext
  );
  const client = useApolloClient();

  const versionResult = useVersionResultsQuery({
    variables: {
      id: versionId,
    },
  });

  const combinedRefetch = async () => {
    versionResult.refetch();
    refetch();
    // temporary solution below (quick bugfix), would be better to go with observable queries
    const queriesToFetch = [
      LEGAL_CHECK_POSSIBLE,
      LEGAL_CHECK_DONE_POSSIBLE,
      COMPLETION_POSSIBLE,
      GET_REQUIREMENTS,
      GET_TODOS,
      SUPPLIER_INPUT_DONE_POSSIBLE, // in SUPPLIERINPUT state
    ];

    await Promise.all(
      queriesToFetch.map((query) =>
        client.query({
          query,
          variables: { versionId },
          fetchPolicy: 'network-only',
        })
      )
    );
  };

  const [checkResolverStatus, resolverStatus] = useCheckResolverStatusLazyQuery(
    {
      onCompleted: async (result) => {
        if (result.CheckResolverStatus?.status === ResolverStatus.RUNNING) {
          await new Promise((resolve) => {
            setTimeout(resolve, 1000);
          });
          checkResolverStatus({
            variables: {
              versionId,
            },
            fetchPolicy: 'network-only',
          });
        } else {
          combinedRefetch();
        }
      },
      fetchPolicy: 'network-only',
      onError: () => {
        combinedRefetch();
      },
    }
  );

  const [startResolver, { error: resolverError }] = useMutation(
    RESOLVE_VERSION,
    {
      variables: {
        versionId,
      },
      onCompleted() {
        checkResolverStatus({
          variables: {
            versionId,
          },
        });
      },
    }
  );

  const [cancelResolver] = useMutation(CANCEL_RESOLVER, {
    variables: {
      versionId,
    },
    refetchQueries: [VERSION_RESULT],
  });

  React.useEffect(() => {
    checkResolverStatus({
      variables: {
        versionId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onStart = () => {
    checkResolverStatus({
      variables: {
        versionId,
      },
      onCompleted: async (result) => {
        if (result.CheckResolverStatus?.status === ResolverStatus.RUNNING) {
          await new Promise((resolve) => {
            setTimeout(resolve, 1000);
          });
          checkResolverStatus({
            variables: {
              versionId,
            },
            fetchPolicy: 'network-only',
          });
        } else {
          startResolver();
        }
      },
    });
  };

  const onCancel = () => {
    cancelResolver();
  };

  const resolverRunning = () => {
    return (
      resolverStatus.data?.CheckResolverStatus?.status ===
      ResolverStatus.RUNNING
    );
  };
  // "initial result" is used as flag to indicate no resolver has been started yet and therefore no request made
  if (versionResult.data) {
    const resolverNotStartedYet =
      versionResult.data.Version?.overallResult.note === 'initial result';
    return isProject ? (
      <div style={{ margin: '14px 0' }} />
    ) : (
      <Segment id="resolverStatus">
        <Grid columns="3">
          <Grid.Column className="flex left gap-large">
            <Header
              size="tiny"
              content={t('dependencyList.tableHeader.resolverStatus')}
            />
            {resolverRunning() ? (
              <div>
                <Icon id="Spinner" loading name="spinner" />
                {t('dependencyList.checkInprogress')}
              </div>
            ) : (
              <Header
                className={`resolver status ${versionResult.data.Version?.overallResult?.status}`}
                size="small"
                content={t(
                  `dependencyList.resolverStatus.${
                    versionResult.data.Version?.overallResult?.status ||
                    'UNKNOWN'
                  }`
                )}
              />
            )}
          </Grid.Column>
          <Grid.Column className="flex center-horizontal">
            <Loader active={resolverRunning()} />
          </Grid.Column>
          <Grid.Column className="flex right gap-small">
            <div
              className={`placeholder-${
                resolverNotStartedYet ? 'invisible' : 'visible'
              }`}
            >
              {!resolverNotStartedYet &&
                t('dependencyList.lastModifiedAt', {
                  date: toLocalDateAndTime(
                    versionResult.data.Version?.resolverResult?.lastModifiedAt,
                    i18n.language
                  ),
                })}
            </div>
            {resolverRunning() ? (
              <Button
                id="Button.Cancel"
                basic
                icon="cancel"
                negative
                floated="right"
                content={t('dependencyList.cancelResolver')}
                onClick={onCancel}
              />
            ) : (
              <Popup
                id="Popup.Start"
                open
                position="top center"
                disabled={
                  resolverError?.message !==
                  `Version ${versionId} has a running resolver or import. Only one resolver or import is allowed at a time.`
                }
                content={t('resolverBlocked')}
                trigger={
                  <Button
                    id="Button.Start"
                    basic
                    icon="play"
                    floated="right"
                    content={t('dependencyList.startResolver')}
                    disabled={
                      !finishedImport ||
                      !authorizations.includes('CheckVersionDependencies')
                    }
                    onClick={onStart}
                  />
                }
              />
            )}
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
  return <div />;
};

export default ResolverSegment;
