import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../../context/UserContext';
import { ProductType } from '../../../../../graphql/generated/graphql';

interface Props {
  editing: boolean;
  setEditing: (editing: boolean) => void;
  editDetailsMutation: () => void;
  types: ProductType[] | undefined;
}

/**
 * @param {object} props {editing, setEditing, editDetails, types}
 * @returns {JSX.Element}  Edit Button Component for Info Box
 */
const EditProductButton = (props: Props): JSX.Element => {
  const { t } = useTranslation('productDetails');
  const {
    editing,
    setEditing,
    editDetailsMutation: editDetails,
    types,
  } = props;
  const { authorizations } = useContext(ProductDetailsContext);
  if (editing)
    return (
      <Button
        id="EditProductButton.Save"
        primary
        disabled={types && types[1].length < 1} // disabled untill both inputs are selected!
        icon
        labelPosition="left"
        onClick={() => {
          setEditing(false);
          editDetails();
        }}
      >
        <Icon id="EditProductButton.Save.Icon" name="check" />
        {t('saveDetails', { ns: 'buttons' })}
      </Button>
    );
  return (
    <Popup
      id="EditProductButton.Popup"
      disabled={authorizations.includes('UpdateVersion')}
      content={t('noChangesAuthorized')}
      trigger={
        <span>
          <Button
            id="EditProductButton.Edit"
            icon
            labelPosition="left"
            basic
            disabled={!authorizations.includes('UpdateVersion')}
            color="blue"
            onClick={() => setEditing(true)}
          >
            <Icon id="EditProductButton.Edit.PencilIcon" name="pencil" />
            {t('editDetails', { ns: 'buttons' })}
          </Button>
        </span>
      }
    />
  );
};

export default EditProductButton;
