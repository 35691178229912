import { DocumentNode, useMutation } from '@apollo/client';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Icon,
  Message,
  Popup,
  Segment,
  TextArea,
} from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../context/UserContext';
import CREATE_A_COMMENT from '../../../../graphql/mutations/CommentMutations';
import { displayedInputType } from './helper/types';

// import { CommentsContext } from '../../../../context/CommentsContext';

type CreateCommentProps = {
  refersToId?: string; // element that references the comment
  createComment: boolean;
  setCreateComment: (createComment: boolean) => void;
  setDisplayedInput: (displayedInput: displayedInputType) => void;
  isReplied?: string | []; // element that
  noComments?: boolean | undefined;
  refetch: (string | DocumentNode)[];
};

/**
 * Comment creating component
 *
 *@returns {JSX.Element} create comment element
 *@param {CreateCommentProps} props Props for the comment
 */
const CreateCommentComponent = (props: CreateCommentProps): JSX.Element => {
  const [t] = useTranslation('comments');
  const { versionId } = useContext(ProductDetailsContext);

  const {
    refersToId,
    createComment,
    setCreateComment,
    setDisplayedInput,
    isReplied,
    noComments,
    refetch,
  } = props;
  const [text, setText] = useState('');
  const [errorMsg, setErrorMsg] = useState(false);
  const [showHint, setShowHint] = useState(false);

  // const { setDisplayedInput } = useContext(CommentsContext);

  useEffect(() => {
    if (createComment === false) {
      setText('');
      setErrorMsg(false);
      setShowHint(false);
    }
  }, [createComment, setText]);
  const [CreateCommentForAnyComponent] = useMutation(CREATE_A_COMMENT, {
    refetchQueries: refetch,
  });
  const handleCreation = () => {
    if (text.trim().length < 2) {
      setErrorMsg(true);
    } else {
      setErrorMsg(false);
      CreateCommentForAnyComponent({
        variables: {
          versionId,
          text: text.trim(),
          refersToId,
          replyCommentId: isReplied || null,
        },
      });
      setCreateComment(false);
      setShowHint(false);
      setDisplayedInput('');
      setText('');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleCreation();
    }
  };

  return (
    <Segment
      id="CreateCommentComponentSegment"
      className="CreateCommentComponentSegment"
      style={{
        marginLeft: 'auto',
        width: '100%',
        border: 'none',
        boxShadow: 'none',
        background: 'transparent',
      }}
    >
      <Form
        id="CreateCommentComponentTextArea"
        reply
        style={{
          marginTop: '10px',
          display: 'flex',
          gap: '1rem',
          alignItems: 'center',
        }}
      >
        {' '}
        <Popup
          id="CreateCommentComponentTextAreaPopup"
          open={errorMsg}
          content={
            <Message
              id="CreateCommentComponentTextAreaMessage"
              warning
              icon="exclamation triangle"
              content={t('invalidComment')}
            />
          }
          position="top center"
          trigger={
            <TextArea
              style={{ resize: 'none' }}
              placeholder={t('addComment')}
              value={text}
              rows={1}
              onChange={(_evt: ChangeEvent<HTMLTextAreaElement>, data) => {
                setErrorMsg(false); // disables error msg to stay when writing
                setText(data.value as string);
                if (data.value && data.value.toString().length > 2) {
                  setShowHint(true);
                } else {
                  setShowHint(false);
                }
              }}
              onKeyDown={handleKeyDown}
            />
          }
        />
        <Icon
          id="CreateCommentComponentConfirmIcon"
          onClick={handleCreation}
          link
          bordered
          inverted
          name="send"
          size="large"
          color="blue"
        />
        {!noComments && (
          <Icon
            id="CreateCommentComponentCancelIcon"
            onClick={() => {
              setCreateComment(false);
              setDisplayedInput('');
              setShowHint(false);
            }}
            name="cancel"
            link
            bordered
            size="large"
            color="grey"
          />
        )}
      </Form>
      <small
        style={{
          marginLeft: '0.5em',
          lineHeight: '2.5',
          color: 'gray',
          transition: 'opacity ease-in-out 0.2s',
          opacity: showHint ? 1 : 0,
        }}
      >
        {t('shiftEnter')}
      </small>
    </Segment>
  );
};

export default CreateCommentComponent;
