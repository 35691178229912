import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Item, Icon } from 'semantic-ui-react';
import { ProductType } from '../../../../../graphql/generated/graphql';
import TypeDropdown from './TypeDropdown';
import InfoIconTooltip from '../../../../ui/InfoIconTooltip';

interface Props {
  productType: ProductType[] | undefined;
  setProductType: Dispatch<SetStateAction<ProductType[] | undefined>>;
  editing?: boolean;
}
/**
 * @param {object} props {productType and setProductType}
 * @returns {JSX.Element}  Product Type Component for Info Box
 */
const ProductTypeItem = (props: Props): JSX.Element => {
  const { t } = useTranslation('productDetails');
  const { productType, setProductType, editing = false } = props;

  return (
    <Item id="ProductType">
      <Item.Content>
        {!editing && (
          <>
            <Item.Meta>
              {t('productType')}
              <InfoIconTooltip infoText={t('productType-info')} />
            </Item.Meta>
            <Item.Header
              style={{
                fontWeight: 'bold',
                color: 'black',
              }}
            >
              <Icon
                style={{ marginRight: '10px' }}
                id="ProductType.CloudIcon"
                name="cloud"
              />
              {productType?.join(' | ')}
            </Item.Header>
          </>
        )}{' '}
        {editing && (
          <TypeDropdown val={productType || []} setVal={setProductType} />
        )}
      </Item.Content>
    </Item>
  );
};

export default ProductTypeItem;
