import { useQuery } from '@apollo/client';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Grid,
  Header,
  Icon,
  Message,
  Segment,
  Table,
} from 'semantic-ui-react';
import {
  AuthorizationsContext,
  SelectedRoleContext,
} from '../../../context/UserContext';
import { Product, Role } from '../../../graphql/generated/graphql';
import { PRODUCTS_LIST } from '../../../graphql/queries/ProductQuerys';
import useLocalStorage from '../../../hooks/useLocalStorage';
import Search from '../listComponents/Search';
import CustomPagination from '../listComponents/pagination/CustomPagination';
import ItemsPerPage from '../listComponents/pagination/ItemsPerPage';
import FilterPills from './listComponents/FilterPills';
import LoadingTable from './listComponents/LoadingTable';
import {
  Column,
  Columns,
  ListType,
  getDefaultColumns,
} from './listComponents/ProductListTypes';
import ProductsListBody from './listComponents/ProductsListBody';
import ProductsListEditModal from './listComponents/ProductsListEditModal';
import ProductsListHeader from './listComponents/ProductsListHeader';
import ProjectViewToggle from './listComponents/ProjectViewToggle';
import {
  useFetchRequestorList,
  useFetchReviewers,
  useFetchRoleSpecificColumns,
  useHandleActivePageState,
  useKeepCurrentPageInBounds,
  useMergeRoleSpecificColumns,
  useRefetchList,
  useTotalPages,
  useUpdateRequestorColumn,
  useUpdateReviewerColumn,
} from './listComponents/helpers/costumHooks';
import {
  activeFilters,
  activeSorters,
} from './listComponents/helpers/formatters';

/**
 * Produktliste - Liefert eine Liste mit
 * Projektstatus, ID, Name, Zieldatum, Resolverstatus, Antragsteller und Kommentaren.
 *
 * @returns {JSX.Element}.
 */
const ProductsList = (): JSX.Element => {
  const [text, i18n] = useTranslation('dashboard');
  const navigate = useNavigate();

  const { data, loading, error, refetch } = useQuery(PRODUCTS_LIST);
  const products: Array<Product> = useMemo(() => {
    return data?.Product || [];
  }, [data]);
  const productAmount: number = data?.ProductAmount || 0;
  const visibleProductAmount = products?.length;
  const { selectedRole } = useContext(SelectedRoleContext);
  const authorizationsArray = useContext(AuthorizationsContext);
  const mayCreateProduct =
    authorizationsArray.includes('CreateProduct') ||
    authorizationsArray.includes('CreateWizardProduct');
  const role: Role = selectedRole;
  const prevRoleRef = useRef<Role | null>(null);

  const [columns, setColumns] = useLocalStorage<Columns>(
    'columns',
    getDefaultColumns(role)
  );

  const activeFiltersArray = useMemo(
    () => (columns ? activeFilters(columns) : []),
    [columns]
  );
  const activeSortersArray = useMemo(
    () => (columns ? activeSorters(columns, ListType.PRODUCTSLIST) : []),
    [columns]
  );

  const [searchString, setSearchString] = useLocalStorage('searchString', '');
  const [itemsPerPage, setItemsPerPage] = useLocalStorage('itemsPerPage', 5);
  const [activePageState, setActivePageState] = useLocalStorage(
    'activePageState',
    1
  );
  const [onlyProjects, setOnlyProjects] = useLocalStorage(
    'onlyProjects',
    false
  );

  const pages = useTotalPages(productAmount, itemsPerPage);
  const roleSpecificColumns = useFetchRoleSpecificColumns(role);
  const requestorList = useFetchRequestorList();
  const reviewersList = useFetchReviewers(role);
  // TODO: 🚩 this logic definitely deserves a refactoring :)
  useUpdateRequestorColumn(columns, requestorList, setColumns);
  useUpdateReviewerColumn(columns, reviewersList, role, setColumns);
  useKeepCurrentPageInBounds(pages, activePageState, setActivePageState);
  useMergeRoleSpecificColumns(columns, roleSpecificColumns, setColumns);

  useEffect(() => {
    if (
      prevRoleRef.current !== null &&
      prevRoleRef.current !== role &&
      roleSpecificColumns
    ) {
      setColumns(roleSpecificColumns);
    }
    prevRoleRef.current = role;
  }, [role]); // eslint-disable-line

  useHandleActivePageState(activePageState, setActivePageState);
  useRefetchList(
    refetch,
    activeSortersArray,
    activeFiltersArray,
    activePageState,
    itemsPerPage,
    searchString,
    onlyProjects
  );

  return (
    <Segment id="UserDashboard">
      <Grid relaxed columns={2}>
        <Grid.Column>
          <Header content={text('dashboardHeader.user')} />
        </Grid.Column>
        <Grid.Column textAlign="right">
          <Icon
            size="large"
            name="question circle"
            id={`DashboardGuideIcon${role}-${i18n.language}`}
          />
        </Grid.Column>
      </Grid>
      <Grid>
        <Grid.Column width={4}>
          <Search
            loading={loading}
            error={error}
            id="ProductsListSearch"
            searchString={searchString}
            onSearchChange={(searchInput: string) => {
              setSearchString(searchInput);
            }}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          {mayCreateProduct && (
            <Button
              primary
              id="ProductsListProduct"
              content={text('buttons.addProduct')}
              icon="plus"
              onClick={() => navigate('/new-product')}
            />
          )}
        </Grid.Column>

        <Grid.Column width={8}>
          <div id="ProjectViewContainer">
            <ProjectViewToggle
              onlyProjects={onlyProjects}
              setOnlyProjects={setOnlyProjects}
            />
            {productAmount > 0 ? (
              <p id="ProductsListProductAmount">
                {text('productCounter', {
                  visibleProductAmount,
                  productAmount,
                })}
              </p>
            ) : (
              <p id="ProductListNothingToShow">{text('nothingToShow')}</p>
            )}
          </div>
        </Grid.Column>
      </Grid>

      <hr style={{ margin: '1em 0em' }} />

      <Grid columns="equal">
        <Grid.Column width={10}>
          {columns && (
            <FilterPills
              column={columns.find((column) => column.name === 'projectStatus')}
              setColumns={setColumns}
            />
          )}
        </Grid.Column>
        <Grid.Column />

        <Grid.Column width={4}>
          {columns && (
            <ProductsListEditModal columns={columns} setColumns={setColumns} />
          )}
        </Grid.Column>
      </Grid>
      {productAmount > 0 || loading ? (
        <>
          <Table
            style={{ width: '100%' }}
            singleLine
            selectable
            id="ProductsListTable"
          >
            <Table.Header>
              {columns && (
                <ProductsListHeader columns={columns} setColumns={setColumns} />
              )}
            </Table.Header>
            {columns && (
              <Table.Body>
                {data && !(error || loading) ? (
                  <ProductsListBody columns={columns} products={products} />
                ) : (
                  <LoadingTable
                    rowAmount={itemsPerPage}
                    columnAmount={
                      columns?.filter((col: Column) => col.permitted === true)
                        .length
                    }
                  />
                )}
              </Table.Body>
            )}
          </Table>
          <Grid columns="equal" className="tableFooter">
            <Grid.Column />
            <Grid.Column width={6}>
              {data && (
                <CustomPagination
                  loading={loading}
                  error={error}
                  totalPages={pages}
                  activePage={activePageState}
                  setActivePage={setActivePageState}
                />
              )}
            </Grid.Column>
            <Grid.Column>
              <ItemsPerPage
                loading={loading}
                error={error}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                itemAmount={productAmount}
              />
            </Grid.Column>{' '}
          </Grid>
        </>
      ) : (
        <Message
          id="ProductsListMsgNoProducts"
          warning
          icon="exclamation triangle"
          content={text('noProducts')}
        />
      )}
    </Segment>
  );
};

export default ProductsList;
