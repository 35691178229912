import React, { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Segment, Transition } from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../context/UserContext';
import ProductHistoryList from '../productHistory/ProductHistoryList';
import ComponentCommentsSegment from './comments/commentsOverview/ComponentCommentsSegment';
import GeneralCommentsSegment from './comments/commentsOverview/GeneralCommentsSegment';
import QuestionsCommentsSegment from './comments/commentsOverview/QuestionsCommentsSegment';
import ProductContactsSegment from './contacts/ProductContactsSegment';
import TakeoverOrUserProductDependencySegment from './dependencySegment/TakeoverOrUserProductDependencySegment';
import ProductInfoBox from './infoBox/ProductInfoBox';
import ProjectInfoBox from './infoBox/ProjectInfoBox';
import RequirementsViewHeader from './requirements/RequirementsViewHeader';
import StorageUpload from './storage/StorageUpload';
import StorageList from './storage/storageList/StorageList';
import ToDosViewHeader from './todos/ToDosViewHeader';
import HistoryCommentsSegment from './comments/commentsOverview/HistoryCommentsSegment';

/**
 * Main Segment of the Product Details. Displays the selection of the Menu in the Header.
 *
 * @returns {JSX.Element} ProductDetailsSegment.
 */
const ProductDetailsSegment = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const { isProject } = useContext(ProductDetailsContext);

  const currentView = searchParams.get('view');

  return (
    <Segment
      id="ProductDetailsDetailsSegment"
      className="product-details-main"
      basic
      tertiary
      raised
    >
      <div className="product-details-main-inner">
        <Transition.Group animation="fade" duration={300}>
          {(() => {
            switch (currentView) {
              case 'Details':
                return (
                  <div
                    id={`tab1-${currentView}`}
                    className="product-details-tab"
                    key={`tab1-${currentView}`}
                  >
                    {isProject ? <ProjectInfoBox /> : <ProductInfoBox />}
                    <ProductContactsSegment />
                    <TakeoverOrUserProductDependencySegment />
                  </div>
                );
              case 'Questions':
                return (
                  <div
                    className="product-details-tab"
                    key={`tab2-${currentView}`}
                  >
                    <RequirementsViewHeader />
                    <ToDosViewHeader />
                  </div>
                );
              case 'History':
                return (
                  <div
                    className="product-details-tab"
                    key={`tab3-${currentView}`}
                  >
                    <ProductHistoryList />
                  </div>
                );
              case 'Storage':
                return (
                  <div
                    key={`tab4-${currentView}`}
                    className="AllCommentsSegmentsContainer product-details-tab"
                  >
                    <StorageUpload show />
                    <StorageList show />
                  </div>
                );
              case 'Comments':
                return (
                  <div
                    key={`tab5-${currentView}`}
                    className="AllCommentsSegmentsContainer product-details-tab"
                  >
                    <GeneralCommentsSegment />
                    <ComponentCommentsSegment />
                    <QuestionsCommentsSegment />
                    <HistoryCommentsSegment />
                  </div>
                );
              default:
                return null; // Render nothing if no view matches
            }
          })()}
        </Transition.Group>
      </div>
    </Segment>
  );
};

export default ProductDetailsSegment;
