import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Item } from 'semantic-ui-react';
import ProtectionModal from './ProtectionModal';
import InfoIconTooltip from '../../../../ui/InfoIconTooltip';

interface Props {
  protection: boolean | undefined;
  setProtection?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  editing?: boolean;
}

/**
 * @param {boolean} props {protection, setProtection, editing}
 * @returns {JSX.Element}  Protection Component for Info Box
 */
const ProtectionItem = (props: Props): JSX.Element => {
  const { t } = useTranslation('productDetails');
  const { protection, setProtection, editing = false } = props;
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      {setProtection && (
        <ProtectionModal
          open={openModal}
          setOpen={setOpenModal}
          protection={protection}
          setProtection={setProtection}
        />
      )}
      <Item id="Protection">
        <Item.Content>
          <Item.Meta>
            {/* ICON INFO IN CIRCLE */}

            {t('protection.meta')}
            <InfoIconTooltip infoText={t('protection-info')} />
            {editing && (
              <Item.Extra
                className="edit-link"
                onClick={() => setOpenModal(true)}
              >
                {t('editProtection', { ns: 'buttons' })}
              </Item.Extra>
            )}
          </Item.Meta>
          <Item.Description style={{ color: 'black', fontWeight: 'bold' }}>
            <Icon id="Protection.Icon" name={protection ? 'check' : 'x'} />
            {t('protection')}
          </Item.Description>
        </Item.Content>
      </Item>
    </>
  );
};

export default ProtectionItem;
