import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Item } from 'semantic-ui-react';
import { Maybe } from '../../../../../graphql/generated/graphql';
import DescriptionModal from './DescriptionModal';

interface Props {
  editing?: boolean;
  description: Maybe<string> | undefined;
  setDescription: Dispatch<SetStateAction<Maybe<string> | undefined>>;
  maxDescriptionLines: number;
}

/**
 * @param {object} props {description and setOpenDescriptionModal}
 * @returns {JSX.Element}  Description Component for Info Box
 */
const DescriptionItem = (props: Props): JSX.Element => {
  const { t } = useTranslation('productDetails');
  const [openModal, setOpenModal] = React.useState(false);
  const {
    editing = false,
    description,
    setDescription,
    maxDescriptionLines,
  } = props;
  const [showMoreButton, setShowMoreButton] = useState(false);

  useEffect(() => {
    const checkIfShouldShowMore = () => {
      const shouldShowMoreCondition =
        (description?.split('\n').length ?? 0) > maxDescriptionLines ||
        (description?.length ?? 0) > 260;
      setShowMoreButton(shouldShowMoreCondition);
    };
    checkIfShouldShowMore();
  }, [description, maxDescriptionLines]);
  return (
    <>
      <DescriptionModal
        editing={editing}
        open={openModal}
        setOpen={setOpenModal}
        description={description || ''}
        setDescription={setDescription}
      />
      <Item id="DescriptionItem">
        <Item.Content>
          <Item.Meta>
            {t('description')}{' '}
            {editing && (
              <Item.Extra
                className="edit-link"
                content={t('editDescription', {
                  ns: 'buttons',
                })}
                onClick={() => {
                  setOpenModal(true);
                }}
              />
            )}
          </Item.Meta>
          <Item.Description>
            <span style={{ WebkitLineClamp: maxDescriptionLines }}>
              {description}
            </span>
            {!editing && showMoreButton && (
              <Item.Extra
                className="ui inline clickable"
                onClick={() => {
                  setOpenModal(true);
                }}
                content={t('show-more', { ns: 'buttons' })}
              />
            )}
          </Item.Description>
          {(description?.length === 0 ||
            description === null ||
            description === undefined) && (
            <Item.Description style={{ color: 'lightgrey' }}>
              {t('noDescription', { ns: 'productDetails' })}
            </Item.Description>
          )}
        </Item.Content>
      </Item>
    </>
  );
};

export default DescriptionItem;
