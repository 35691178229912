import { useQuery } from '@apollo/client';
import React, { Fragment, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Divider,
  Icon,
  Label,
  Menu,
  Message,
  Segment,
} from 'semantic-ui-react';

import { ProductDetailsContext } from '../../../../../context/UserContext';
import { Comment } from '../../../../../graphql/generated/graphql';
import { ALL_DEPENDENCY_COMMENTS } from '../../../../../graphql/queries/CommentQuerys';
import ErrorAndLoadingMessage from './ErrorAndLoadingMessage';
import Discussions, { DiscussionsReset } from '../Discussions';
import GenericClosedSegmentSkeleton from '../../skeletons/GenericClosedSegmentSkeleton';
import { extractFeaturesComments, filterReferences } from '../helper/logics';
/**
 * @returns {JSX.Element} The element returning an overview of all component comments-in this case all dependencies of a version/product
 */
const ComponentCommentsSegment = (): JSX.Element => {
  const [t, i18n] = useTranslation(['comments']);
  const i18nLang = i18n.language;
  const [open, setOpen] = useState(false);
  const { versionId } = useContext(ProductDetailsContext);

  const ref = useRef<DiscussionsReset>(null);

  const resetAndClose = () => {
    ref?.current?.reset();
    setOpen(!open);
  };

  const { data, loading, error } = useQuery(ALL_DEPENDENCY_COMMENTS, {
    variables: {
      versionId,
    },
    fetchPolicy: 'cache-and-network', // 🌶️ TODO: Check if we can remove this somehow
  });

  if (loading && !open) {
    <GenericClosedSegmentSkeleton
      image
      lines={1}
      labels="all"
      linesLength="long"
    />;
  }

  if (data) {
    const allDependenciesComments = data.Comments || [];
    const majorComments = allDependenciesComments.filter(
      (comment: Comment) => comment.replied === null
    );

    // this finds refersTo-component infos for dependency from its id-comments are beeing listed regarding component they exist in
    const findReferenceInfos = (id: string, part: string) => {
      const findElement = majorComments.filter(
        (el: Comment) => el.refersTo?.id === id
      );
      return part === 'componentName'
        ? findElement[0].refersTo?.componentName
        : findElement[0].refersTo?.componentVersion;
    };

    return (
      <Segment id="ComponentCommentsSegment">
        <Menu id="ComponentCommentsSegmentMenu" secondary compact>
          <Menu.Item>
            <Icon
              id="ComponentCommentsSegmentAccordion"
              onClick={resetAndClose}
              name={open ? 'chevron up' : 'chevron down'}
              color={open ? 'blue' : 'black'}
            />
          </Menu.Item>
          <Menu.Item
            content={t('onComponents')}
            style={{ fontWeight: 'bold', fontSize: '16px' }}
          />
          <Menu.Item>
            <Label id="CommentsNumberLabel" circular style={{ margin: 0 }}>
              {allDependenciesComments.length}
            </Label>
            <Icon
              size="large"
              name="question circle"
              id={`OnCompCommentGuideIcon-${i18nLang}`}
              style={{
                marginLeft: '0.5rem',
                display: 'none',
                // TODO: delete display none when guide is ready
              }}
            />
          </Menu.Item>
        </Menu>
        {/* Comments View - comments are listed for each component they are being refered to */}
        {open && (
          <div className="AllCommentsSegmentCommentsOverview">
            {data.Comments.length > 0 ? (
              filterReferences(majorComments).map((efs) => {
                return (
                  <Fragment
                    key={`AllCommentsSegmentCommentsOverviewDependencyName${efs}`}
                  >
                    <div
                      style={{
                        width: 'fit-content',
                        margin: '2em 0 0 1em',
                      }}
                    >
                      <span className="AllCommentsSegmentCommentsOverviewDependencyName">
                        {findReferenceInfos(efs as string, 'componentName')}
                      </span>
                      <span className="AllCommentsSegmentCommentsOverviewDependencyVersion">
                        {findReferenceInfos(efs as string, 'componentVersion')}
                      </span>
                      <Divider fitted />
                    </div>
                    {/* here we list the comments which together comming from above listed refered component  */}
                    <Discussions
                      ref={ref}
                      comments={extractFeaturesComments(
                        efs as string,
                        allDependenciesComments
                      )}
                      refersToId={efs}
                      refetch={[ALL_DEPENDENCY_COMMENTS]}
                    />
                  </Fragment>
                );
              })
            ) : (
              <Message
                id="ComponentCommentsSegmentNoCommentsYet"
                info
                icon="info circle"
                header={t('noComponentCommentsYet')}
                content={t('noComponentCommentsCreate')}
              />
            )}
          </div>
        )}
      </Segment>
    );
  }

  if (error || loading) {
    return (
      <ErrorAndLoadingMessage
        error={error}
        loading={loading}
        source="onComponents"
      />
    );
  }
  return <div />;
};

export default ComponentCommentsSegment;
