import React from 'react';
import { useTranslation } from 'react-i18next';
import { Item } from 'semantic-ui-react';
import { Maybe } from '../../../../../graphql/generated/graphql';

interface Props {
  priority: Maybe<string> | undefined;
}

/**
 * @param {string} props {priority}
 * @returns {JSX.Element}  Priority Component for Info Box
 */
const PriorityItem = (props: Props): JSX.Element => {
  const { t } = useTranslation('productDetails');
  const { priority } = props;

  return (
    <Item id="Priority">
      <Item.Content>
        <Item.Meta>{t('priority')}</Item.Meta>
        <Item.Description style={{ fontWeight: 'bold' }} content={priority} />
      </Item.Content>
    </Item>
  );
};

export default PriorityItem;
