import { useApolloClient, useMutation } from '@apollo/client';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../context/UserContext';
import { useCheckResolverStatusLazyQuery } from '../../../../graphql/generated/graphql';
import {
  LEGAL_CHECK_DONE_POSSIBLE,
  PRODUCT_DETAILS_CONTEXT,
} from '../../../../graphql/queries/VersionQuerys';
import { ResolverStatus } from '../dependencySegment/dependencyList/helper/types';
import { StateMapping } from './helper/logics';

type Props = {
  id: string;
  disabled: boolean;
  state: StateMapping;
};

/**
 * Main Segment of the Product Details for Guest. Displays the selection of the Menu in the Header.
 *
 * @param {object} props id of the product.
 * @returns {JSX.Element} GuestProductDetailsSegment.
 */
const StateChangeButton = (props: Props): JSX.Element => {
  const { id, state, disabled } = props;
  const { versionId } = useContext(ProductDetailsContext);
  const { t } = useTranslation('productDetails');
  const client = useApolloClient();

  const isFinishLegalCheck = () => {
    return state.mutationName === 'FinishLegalCheck';
  };
  const finishCheckLoading =
    state.mutationName === 'FinishLegalCheck' ||
    state.mutationName === 'FinishProductCheck' ||
    state.mutationName === 'FinishProjectCheck';
  const [checkResolverStatus] = useCheckResolverStatusLazyQuery({
    variables: {
      versionId,
    },
    onCompleted: async (result) => {
      if (result.CheckResolverStatus?.status === ResolverStatus.RUNNING) {
        await new Promise((resolve) => {
          setTimeout(resolve, 1000);
        });
        checkResolverStatus({
          variables: {
            versionId,
          },
          fetchPolicy: 'network-only',
        });
      } else {
        client.refetchQueries({
          include: [PRODUCT_DETAILS_CONTEXT, LEGAL_CHECK_DONE_POSSIBLE],
        });
      }
    },
    fetchPolicy: 'network-only',
  });

  const [StateChangeMutation, { data, loading }] = useMutation(state.mutation, {
    variables: { versionId },
    refetchQueries: [PRODUCT_DETAILS_CONTEXT],
    onCompleted: () => {
      if (isFinishLegalCheck()) {
        checkResolverStatus();
      }
    },
  });

  const OSOIsActive = state.mutationName === 'SubmitToOSO';

  return (
    <Popup
      id="Active Buttons Popup"
      disabled={!OSOIsActive}
      content={t('stateChanges.submitToOsoInfoText')}
      trigger={
        <Button
          id={id}
          className="stateChangeButton"
          primary
          basic
          content={
            <>
              {finishCheckLoading && (data || loading) && (
                <Popup
                  id="LoadingPopup"
                  content={t('finishCheckLoading')}
                  trigger={<Icon id="Spinner" loading name="spinner" />}
                />
              )}
              {t(state.content)}
            </>
          }
          disabled={disabled || loading}
          onClick={() => StateChangeMutation()}
        />
      }
    />
  );
};

export default StateChangeButton;
