import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import { ProductDetailsContext } from '../../../../../../../context/UserContext';
import {
  DependencySubComponentsRelationship,
  Maybe,
  Result,
} from '../../../../../../../graphql/generated/graphql';
import LegalResultModal from './legalResultModal/LegalResultModal';

type PropsType = {
  overallResult: Maybe<Result>;
  name: string;
  id: string;
  legalResult?: Maybe<Result> | undefined;
  licenseTextEdges: Array<DependencySubComponentsRelationship>;
};

/**
 * Product Version Dependency result status
 * Element showing the resolver status e.g. OK, CONFLICT, etc.
 *
 * @param {PropsType} props takes in array of the results
 * @returns {JSX.Element} a span with status and colored border
 */
const ResolverStatusElement = (props: PropsType): JSX.Element => {
  const {
    overallResult,
    name,
    id: dependencyId,
    legalResult,
    licenseTextEdges,
  } = props;
  const [text] = useTranslation('dashboard');
  const [openLegalCheck, setOpenLegalCheck] = useState(false);
  const { authorizations } = useContext(ProductDetailsContext);

  return (
    <>
      <LegalResultModal
        open={openLegalCheck}
        setOpen={setOpenLegalCheck}
        id={dependencyId}
        name={name}
        currentStatus={overallResult?.status ? overallResult.status : 'UNKNOWN'}
        legalResult={legalResult}
        licenseTextEdges={licenseTextEdges}
        resultNode="Dependency"
      />
      {overallResult && (
        <div className="prevent-icon-overflow">
          <span className="text">
            {text(
              `tableElements.resolverStatusInformation.${overallResult.status}`
            )}
          </span>
          {authorizations.includes('CreateLegalDependencyResult') && (
            <Icon
              id="ResolverStatusElementLegalCheckIcon"
              name="pencil"
              color="blue"
              link
              onClick={() => setOpenLegalCheck(true)}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ResolverStatusElement;
