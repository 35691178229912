import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Item } from 'semantic-ui-react';
import CalendarForm from '../../../../ui/CalendarForm';
import TargetDateMessage from './TargetDateMessage';

interface Props {
  targetDate: string | undefined;
  setTargetDate: Dispatch<SetStateAction<string | undefined>>;
  editing?: boolean;
}

/**
 * @param {Date} props {targetDate, editing}
 * @returns {JSX.Element}  Target Date Component for Info Box
 */
const TargetDateItem = (props: Props): JSX.Element => {
  const { t } = useTranslation('productDetails');
  const { targetDate, setTargetDate, editing = false } = props;
  return (
    <Item id="TargetDate" className="deadline">
      <Item.Content>
        <Item.Meta content={t('targetDate')} />
        {!editing ? (
          <Item.Header>
            {targetDate
              ? new Date(targetDate).toLocaleDateString(undefined, {
                  timeZone: 'UTC',
                })
              : ''}
            <TargetDateMessage targetDate={targetDate} />
          </Item.Header>
        ) : (
          <CalendarForm
            targetDate={targetDate}
            setTargetDate={setTargetDate}
            format="short"
          />
        )}
      </Item.Content>
    </Item>
  );
};

export default TargetDateItem;
