import React, { useContext, useState } from 'react';
import { ApolloError, OperationVariables } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  Header,
  Icon,
  Label,
  Loader,
  Message,
  Modal,
  Segment,
} from 'semantic-ui-react';
import DependencyTree from './DependencyTree';
import {
  Dependency,
  Maybe,
} from '../../../../../../../../../graphql/generated/graphql';
import CustomPagination from '../../../../../../../listComponents/pagination/CustomPagination';
import { ProductDetailsContext } from '../../../../../../../../../context/UserContext';
import useEffectWithPrevDeps from '../../../../helper/useEffectWithPrevDeps';

type PropsType = {
  dependencyId: Maybe<string> | undefined;
  sourceDepId: Maybe<string> | undefined;
  depTreeData: Dependency[];
  depTreeLoading: boolean;
  depTreeAmountData: Maybe<number> | undefined;
  depTreeAmountLoading: boolean;
  depTreeAmountError: ApolloError | undefined;
  open: boolean;
  setOpen: (value: boolean | ((prevValue: boolean) => boolean)) => void;
  onComponentClick?: (name: string) => void;
  refetchDepTree: (variables?: OperationVariables | undefined) => void;
  dependencyCount: number;
  setDependencyCount: (value: number | ((prevValue: number) => number)) => void;
};

/**
 * @param {PropsType} props id to source dependency
 * @returns {JSX.Element}  Modal for displaying dependency tree
 */
const DependencyTreeModal = (props: PropsType): JSX.Element => {
  const {
    dependencyId,
    sourceDepId,
    depTreeData,
    depTreeLoading,
    depTreeAmountData,
    depTreeAmountLoading,
    depTreeAmountError,
    setOpen,
    open,
    onComponentClick,
    refetchDepTree,
    dependencyCount,
    setDependencyCount,
  } = props;
  const { versionId } = useContext(ProductDetailsContext);

  const [activePageState, setActivePageState] = useState<number>(1);

  useEffectWithPrevDeps(
    ([oldActivePageState]) => {
      if (oldActivePageState !== activePageState) {
        refetchDepTree({
          versionId,
          dependencyId,
          sourceDependencyId: sourceDepId,
          offset: activePageState - 1,
        });
      }
    },
    [activePageState, dependencyId, refetchDepTree, sourceDepId, versionId]
  );

  const { t } = useTranslation(['productDetails']);

  const dependency = depTreeData.find((dep: Dependency) => {
    return dep.id === dependencyId;
  });

  const sourceDependency = depTreeData.find((dep: Dependency) => {
    return dep.id === sourceDepId;
  });

  return (
    <Modal
      id="TreeModal"
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      size="tiny"
      style={{ minHeight: '50%' }}
    >
      <Modal.Header id="TreeModal.Header">
        {`${t('dependencyList.treeModal.header')}`}
        <Icon
          id="iconClose1"
          name="close"
          link
          floated="right"
          onClick={() => setOpen((prevState: boolean) => !prevState)}
        />
      </Modal.Header>
      <Modal.Content id="TreeModal.Content">
        {depTreeLoading && <Loader />}
        {depTreeData && (
          <>
            <Header as="h4" style={{ display: 'inline' }}>
              {dependency?.componentName} |{' '}
            </Header>
            <span>
              <strong>
                {sourceDependency?.componentName || 'source-unknown'}
              </strong>
              {` ${t('dependencyList.treeModal.selected')}`}
              <Label id="TreeModal.Label" content={dependencyCount} />
              {` ${t('dependencyList.treeModal.linked')}`}
            </span>
            <Segment id="TreeModal.Segment" className="tree">
              <DependencyTree
                deps={depTreeData}
                sourceDepId={sourceDepId}
                targetDepId={dependencyId}
                setDependencyCount={setDependencyCount}
                onComponentClick={onComponentClick}
              />
            </Segment>
          </>
        )}
        {!depTreeData && !depTreeLoading && (
          <Message
            id="DependencyTreeModalMsg"
            error
            icon="times circle"
            content={t('dependencyList.treeModal.error')}
          />
        )}
      </Modal.Content>
      {depTreeData && depTreeAmountData && depTreeAmountData > 1 ? (
        <Modal.Actions>
          <CustomPagination
            loading={depTreeAmountLoading}
            error={depTreeAmountError}
            totalPages={depTreeAmountData}
            activePage={activePageState}
            setActivePage={setActivePageState}
          />
        </Modal.Actions>
      ) : null}
    </Modal>
  );
};

export default DependencyTreeModal;
