import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Item } from 'semantic-ui-react';
import { DistributionNamesLocalized } from '../../../../wizard/WizardConstants';
import DistributionModal from './DistributionModal';
import InfoIconTooltip from '../../../../ui/InfoIconTooltip';

interface Props {
  editing?: boolean;
  distribution: string | undefined;
  setDistribution: Dispatch<SetStateAction<string | undefined>>;
}

/**
 * @param {object} props {editing?, distribution, setOpenDistributionModal}
 * @returns {JSX.Element}  Distribution Component for Info Box
 */
const DistributionItem = (props: Props): JSX.Element => {
  const { t } = useTranslation('productDetails');
  const { editing = false, distribution, setDistribution } = props;
  const [openModal, setOpenModal] = useState(false);

  const distributionNamesLocalized = DistributionNamesLocalized();
  const distributionName = Object.entries(distributionNamesLocalized).find(
    (dist) => dist[0] === distribution
  )?.[1];

  return (
    <>
      <DistributionModal
        open={openModal}
        setOpen={setOpenModal}
        distribution={distribution}
        setDistribution={setDistribution}
      />
      <Item id="DistributionItem">
        <Item.Content>
          <Item.Meta>
            {t('distribution')}
            <InfoIconTooltip infoText={t('distribution-info')} />
            {editing && (
              <Item.Extra
                className="ui inline clickable"
                onClick={() => {
                  setOpenModal(true);
                }}
                content={t('change', { ns: 'buttons' })}
              />
            )}
          </Item.Meta>
          <Item.Header
            style={{
              fontWeight: 'bold',
              color: 'black',
            }}
          >
            {distributionName}
          </Item.Header>
        </Item.Content>
      </Item>
    </>
  );
};

export default DistributionItem;
