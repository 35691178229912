import React, {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useState,
} from 'react';
import { DateInput } from 'semantic-ui-calendar-react';
import i18n from '../../i18n/i18n';
import formatDateForView from '../products/productsList/listComponents/columnComponents/helpers/dateFormat';

interface CalendarFormProps {
  targetDate: string | undefined;
  setTargetDate: Dispatch<SetStateAction<string | undefined>>;
  placeholder?: string;
  format?: 'full' | 'short';
}

/**
 * A calendar input component that allows users to select dates with optional formatting.
 * ** the semantic-ui-calendar-react is not beeing maintained and the minDate property causes error if the value contains a date that is not in the scope-meaning older than current month. **
 *
 * @param {object} props - Component props.
 * @param {string | Date | undefined} props.targetDate - The selected target date.
 * @param {Function} props.setTargetDate - Function to set the target date.
 * @param {string} props.placeholder - Placeholder text for the input.
 * @param {'full'|'short'} props.format - Format option for the date display ('full' or 'short').
 * @returns {JSX.Element} The CalendarForm component.
 */
const CalendarForm = (props: CalendarFormProps): JSX.Element => {
  const {
    targetDate,
    setTargetDate,
    placeholder = '',
    format = 'full',
  } = props;

  const currentLanguage = i18n.language;
  const [newTargetDate, setNewTargetDate] = useState('');

  const swapDDwithMM = (dateString: string) => {
    const numericParts = dateString.match(/\d+/g);
    if (numericParts && numericParts.length === 3) {
      const day = numericParts[0];
      const month = numericParts[1];
      const year = numericParts[2];
      const dayName = dateString.replace(numericParts.join('/'), '').trim();

      return dayName.length > 1
        ? `${dayName},${month}/${day}/${year}`
        : `${month}/${day}/${year}`;
    }
    return dateString;
  };

  const pad = (num: number) => String(num).padStart(2, '0');

  const handleOnChange = (dateInput: string) => {
    const dateInputWithoutWeekday = dateInput.replace(/^[a-zA-Z]+,\s*/, '');

    const [day, month, year] = (
      currentLanguage === 'de'
        ? dateInputWithoutWeekday.split('/')
        : swapDDwithMM(dateInputWithoutWeekday).split('/')
    ).map(Number);

    const newTargetDateISO = `${year}-${pad(month)}-${pad(day)}T00:00:00.000Z`;
    setTargetDate(newTargetDateISO);
    setNewTargetDate(newTargetDateISO);
  };

  const definedOrNew = (): string => {
    if (targetDate && !newTargetDate) {
      return targetDate;
    }
    return newTargetDate;
  };

  const fullFormat = formatDateForView(definedOrNew(), 'full');
  const shortFormat = formatDateForView(definedOrNew(), 'short');
  const dateFormat = () => {
    if (format === 'full') {
      return currentLanguage === 'en' ? 'dddd,M/D/YYYY' : 'dddd,D/M/YYYY';
    }
    return currentLanguage === 'en' ? 'M/D/YYYY' : 'D/M/YYYY';
  };

  const formatedDate = format === 'full' ? fullFormat : shortFormat || '';

  // Check if today is the last day of the month
  const today = new Date();
  // "today.getMonth() + 1" finds next month and "0" rolls back to the last day of the current month.
  const isLastDayOfMonth =
    today.getDate() ===
    new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();

  // to bypass the minDate bug-we use placeholder to display value if targetDate is older than current month
  const isCurrentOrFutureMonth = (targetdate: string | undefined) => {
    const utcToday = new Date(
      Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())
    );
    const target = new Date(targetdate || '');
    // Return calculated result based on the comparison
    const isCurrentOrFuture =
      target.getFullYear() > utcToday.getFullYear() ||
      (target.getFullYear() === utcToday.getFullYear() &&
        target.getMonth() >= utcToday.getMonth());
    // if targetDate is older than current month => value is set to '' and placeholder overtakes formatedDate
    return {
      isCurrentOrFuture,
      dateValue: isCurrentOrFuture ? formatedDate : '',
      placeholderVal: isCurrentOrFuture ? placeholder : formatedDate,
    };
  };
  const { dateValue, placeholderVal } = isCurrentOrFutureMonth(targetDate);

  return (
    <DateInput
      id="targetDate"
      name="targetDate"
      closable
      autoComplete="off"
      style={{ width: '100%' }}
      dateFormat={dateFormat()}
      localization={currentLanguage}
      placeholder={placeholderVal}
      value={dateValue}
      iconPosition="right"
      popupPosition="top right"
      onChange={(_e, { value }) => {
        handleOnChange(value);
      }}
      onKeyDown={(e: SyntheticEvent) => {
        e.preventDefault();
      }}
      minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
      initialDate={
        isLastDayOfMonth
          ? new Date(today.getFullYear(), today.getMonth() + 1, 1)
          : undefined
      }
    />
  );
};

export default CalendarForm;
