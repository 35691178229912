import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Label, Menu, Message, Modal } from 'semantic-ui-react';
import {
  Comment,
  RequirementInfo,
} from '../../../../graphql/generated/graphql';
import RequirementsTab from '../requirements/RequirementsTab';
import { CommentsModalContext } from '../requirements/helper/Constants';
import Discussions, { DiscussionsReset } from './Discussions';
import { getRefetchQuery } from './helper/logics';
import { OpenCommentsModalState } from './helper/types';
import GenericClosedSegmentSkeleton from '../skeletons/GenericClosedSegmentSkeleton';
import useCommentsQuery from './helper/useCommmentsQuery';
import { ProductDetailsContext } from '../../../../context/UserContext';

type CommentsModalWindowProps = {
  openCommentsModal: OpenCommentsModalState;
  setOpenCommentsModal: (openCommentsModal: OpenCommentsModalState) => void;
  elementName: string | React.ReactNode;
  comments?: Comment[] | undefined;
  refersToId?: string | undefined;
  requirementInfo?: RequirementInfo;
};

/**
 * Discussions, comments and replies.
 *
 *@returns {JSX.Element} Modal with all comments of the chosen element
 *@param {CommentsModalWindowProps} props Props
 */
const CommentsModalWindow = (props: CommentsModalWindowProps): JSX.Element => {
  const [t] = useTranslation('comments');
  const {
    openCommentsModal,
    setOpenCommentsModal,
    requirementInfo,
    elementName,
    comments,
    refersToId,
  } = props;
  const { versionId } = useContext(ProductDetailsContext);
  // for when comments are opened from Requirements/Todos/Comments(in dependency list)
  const openContext = openCommentsModal[1];
  const openOnQuestions =
    openContext === CommentsModalContext.Requirements ||
    openContext === CommentsModalContext.Todos;

  const initialActiveTab =
    openContext === CommentsModalContext.Requirements
      ? CommentsModalContext.Requirements
      : CommentsModalContext.Comments;

  const [activeTab, setActiveTab] = useState(CommentsModalContext.Requirements);
  const { commentsVar, isLoading, error } = useCommentsQuery(
    versionId as string,
    refersToId as string,
    openContext
  );
  const historyElement = openCommentsModal[1] === CommentsModalContext.History;
  const letComments =
    openOnQuestions || historyElement ? commentsVar : comments;

  useEffect(() => {
    setActiveTab(initialActiveTab);
  }, [openCommentsModal, initialActiveTab]);

  const ref = useRef<DiscussionsReset>(null);

  const resetAndClose = () => {
    ref?.current?.reset();
    setOpenCommentsModal([false, null]);
  };

  return (
    <Modal
      id="CommentsModalWindow"
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
      open={openCommentsModal[0] === true}
    >
      <Modal.Header>
        {openOnQuestions || historyElement ? (
          elementName
        ) : (
          <span className="ComponentHeader">
            {`${t('header')}`}
            &nbsp;
            <em id="CommentsModalWindowElementName">{` ${elementName} `}</em>
            <Label
              id="CommentsModalWindowNumberOfComments"
              color="black"
              style={{ marginLeft: '10px' }}
              content={letComments?.length}
            />
          </span>
        )}
        <Icon
          id="CommentsModalWindowCloseButton"
          name="close"
          link
          style={{ marginLeft: 'auto' }}
          onClick={resetAndClose}
        />
      </Modal.Header>
      {/* MENU FOR QUESTIONS/REQUIREMENTS */}
      {openOnQuestions && (
        <div>
          <Menu
            id="CommentsElementModalMenuTabs"
            className="CommentsElementModalMenuTabs"
            secondary
            pointing
          >
            {openContext === CommentsModalContext.Requirements && (
              <Menu.Item
                name="Product license details"
                active={activeTab === CommentsModalContext.Requirements}
                onClick={() => setActiveTab(CommentsModalContext.Requirements)}
              >
                {t('questionsTab')}
              </Menu.Item>
            )}
            <Menu.Item
              name="License features"
              active={activeTab === CommentsModalContext.Comments}
              onClick={() => setActiveTab(CommentsModalContext.Comments)}
            >
              {t('comments')}
              <Label
                id="LicenseFeaturesCountLabel"
                circular
                color="blue"
                content={letComments?.length}
              />
            </Menu.Item>
          </Menu>
        </div>
      )}
      {/* THIS TABS ARE OPENED IF MODAL IS OPENED IN QUESTIONS/REQUIREMENTS */}
      {openContext === CommentsModalContext.Requirements &&
        requirementInfo &&
        activeTab === CommentsModalContext.Requirements && (
          <RequirementsTab
            requirementInfo={requirementInfo}
            closeModal={resetAndClose}
          />
        )}
      {/* IS OPENED ALWAYS EXCEPT WHEN activeTab === MenuTab.Questions */}
      {(openContext !== CommentsModalContext.Requirements ||
        activeTab === CommentsModalContext.Comments) && (
        <Modal.Content
          className={
            openContext === CommentsModalContext.Requirements
              ? ''
              : 'OpenContext'
          }
        >
          {isLoading ? (
            <GenericClosedSegmentSkeleton
              height={105}
              image
              lines={2}
              labels="all"
              linesLength="short"
            />
          ) : (
            <Discussions
              ref={ref}
              comments={letComments?.slice(0).reverse()}
              refersToId={refersToId}
              isModal
              refetch={[getRefetchQuery(openContext)]}
            />
          )}
          {error && (
            <Message
              id="RequirementCommentsError"
              error
              icon="times circle"
              content={`Error ${error.message} !`}
            />
          )}
        </Modal.Content>
      )}
    </Modal>
  );
};

export default CommentsModalWindow;
