import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Form, Select } from 'semantic-ui-react';
import {
  OptionTypes,
  DistributionOptions,
  BinaryCodeOptions,
  SourceRecipientsOptions,
  ServerUsageOptions,
  DropdownOptions,
} from '../../../../wizard/WizardConstants';
import WizardFormFieldLabel from '../../../../wizard/WizardFormFieldLabel';

interface DistributionEditingModalProps {
  open: boolean;
  setOpen(openDistribution: boolean): void;
  distribution: string | undefined;
  setDistribution: Dispatch<SetStateAction<string | undefined>>;
}

/**
 *@param {string} openDistribution openModal
 *@returns {JSX.Element}  Editing modal for User updating distribution property
 */
const DistributionModal = ({
  open,
  setOpen,
  distribution: actualDistId,
  setDistribution: setActualDistId,
}: DistributionEditingModalProps): JSX.Element => {
  const [t] = useTranslation(['newProductWizard', 'buttons', 'productDetails']);
  const [chosenDist, setChosenDist] = useState<string | undefined>();
  const [recipient, setRecipient] = useState<string | undefined>(actualDistId);

  const serverUsageOptions: Array<DropdownOptions> = ServerUsageOptions();
  const sourceRecipientsOptions = SourceRecipientsOptions();
  const binaryCodeOptions = BinaryCodeOptions();

  useEffect(() => {
    if (actualDistId && chosenDist === undefined) {
      if (serverUsageOptions.find((opt) => opt.value === actualDistId)) {
        setChosenDist('noDist');
      } else if (
        sourceRecipientsOptions.find((opt) => opt.value === actualDistId)
      ) {
        setChosenDist('distSource');
      } else if (binaryCodeOptions.find((opt) => opt.value === actualDistId)) {
        setChosenDist('binaryCode');
      }
    }
  }, [
    actualDistId,
    binaryCodeOptions,
    chosenDist,
    serverUsageOptions,
    sourceRecipientsOptions,
  ]);

  const handleDistUpdate = () => {
    setActualDistId(recipient);
    setOpen(false);
    setChosenDist(undefined);
  };

  return (
    <Modal
      size="tiny"
      id="UserDistributionEditingModal"
      open={open === true}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      <Modal.Header content={t('distribution', { ns: 'productDetails' })} />
      <Modal.Content>
        <Modal.Description>
          <h5 style={{ color: 'lightgrey' }}>
            {t('description', { ns: 'productDetails' })}
          </h5>
          <p id="UserDistributionEditingModalDistributionDescription">
            {t('distributionDescription', { ns: 'productDetails' })}
          </p>
        </Modal.Description>
        <Form
          fluid="true"
          id="UserDistributionEditingModalDistribution"
          style={{ marginTop: '40px' }}
        >
          <Form.Group style={{ justifyContent: 'space-evenly' }}>
            <div>
              <WizardFormFieldLabel
                nameText={t('intendedUsePart.distribution')}
                infoText={t('intendedUsePart.distribution-info')}
              />
              <Form.Field
                control={Select}
                options={DistributionOptions()}
                defaultOpen={false}
                value={chosenDist || ''}
                placeholder={t('pleaseSelect')}
                onChange={(_e: ChangeEvent, { value }: OptionTypes) => {
                  setChosenDist(value);
                  setRecipient(undefined);
                }}
              />
            </div>
            {chosenDist === 'noDist' && (
              <div>
                <WizardFormFieldLabel
                  nameText={t('intendedUsePart.serverUsage')}
                  infoText={t('intendedUsePart.serverUsage-info')}
                />
                <Form.Field
                  control={Select}
                  options={serverUsageOptions}
                  name="distribution"
                  value={recipient || ''}
                  placeholder={t('pleaseSelect')}
                  onChange={(_e: ChangeEvent, { value }: OptionTypes) => {
                    setRecipient(value);
                  }}
                />
              </div>
            )}
            {chosenDist === 'distSource' && (
              <div>
                <WizardFormFieldLabel
                  nameText={t('intendedUsePart.sourceRecipients')}
                  infoText={t('intendedUsePart.sourceRecipients-info')}
                />
                <Form.Field
                  control={Select}
                  options={sourceRecipientsOptions}
                  name="distribution"
                  value={recipient || ''}
                  placeholder={t('pleaseSelect')}
                  onChange={(_e: ChangeEvent, { value }: OptionTypes) => {
                    setRecipient(value);
                  }}
                />
              </div>
            )}
            {chosenDist === 'binaryCode' && (
              <div>
                <WizardFormFieldLabel
                  nameText={t('intendedUsePart.binaryRecipients')}
                  infoText={t('intendedUsePart.binaryRecipients-info')}
                />
                <Form.Field
                  control={Select}
                  options={binaryCodeOptions}
                  name="distribution"
                  value={recipient || ''}
                  placeholder={t('pleaseSelect')}
                  onChange={(_e: ChangeEvent, { value }: OptionTypes) => {
                    setRecipient(value);
                  }}
                />
              </div>
            )}
          </Form.Group>
        </Form>
      </Modal.Content>

      <Modal.Actions style={{ textAlign: 'center' }}>
        {recipient !== actualDistId && recipient !== undefined ? (
          <Button
            style={{ margin: '0 auto', padding: '0, 15px' }}
            primary
            id="UserDistributionEditingModalUpdateBtn"
            content={t('saveChanges', { ns: 'buttons' })}
            onClick={() => {
              handleDistUpdate();
            }}
          />
        ) : (
          <Button
            style={{ margin: '0 auto' }}
            basic
            primary
            id="UserDistributionEditingModalCloseBtn"
            content={t('close', { ns: 'buttons' })}
            onClick={() => {
              setOpen(false);
              setRecipient(actualDistId);
              setChosenDist(undefined);
            }}
          />
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default DistributionModal;
