import { useQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Item, Message, Segment } from 'semantic-ui-react';
import {
  Maybe,
  ProductType,
  Role,
  useEditProductDetailsMutation,
} from '../../../../graphql/generated/graphql';
import DescriptionItem from './components/DescriptionItem';
import DistributionItem from './components/DistributionItem';
import EditProductButton from './components/EditProductButton';
import PriorityItem from './components/PriorityItem';
import ProductTypeItem from './components/ProductTypeItem';
import ProtectionItem from './components/ProtectionItem';
import StandardSoftwareItem from './components/StandardSoftwareItem';
import TargetDateItem from './components/TargetDate';

import {
  ProductDetailsContext,
  SelectedRoleContext,
} from '../../../../context/UserContext';
import {
  LEGAL_CHECK_DONE_POSSIBLE,
  LEGAL_CHECK_POSSIBLE,
  PRODUCT_INFOBOX,
} from '../../../../graphql/queries/VersionQuerys';
import InfoboxPlaceholder from '../skeletons/InfoboxSkeleton';
import HighPriorityRequestItem from './components/HighPriorityRequestItem';
import InfoBoxHeader from './components/InfoBoxHeader';

interface Props {
  maxDescriptionLines?: number;
}

/**
 * @param {string} props {maxDescriptionLines}
 * @returns {JSX.Element} Info Box Component
 */
const ProductInfoBox = (props: Props): JSX.Element => {
  const [t] = useTranslation(['productDetails']);
  const { maxDescriptionLines = 5 } = props;
  const [open, setOpen] = useState<boolean>(true);
  const [editing, setEditing] = useState<boolean>(false);
  const [description, setDescription] = useState<Maybe<string> | undefined>();
  const [types, setTypes] = useState<ProductType[] | undefined>();
  const [targetDate, setTargetDate] = useState<string>();
  const [highPriority, setHighPriority] = useState<boolean>();
  const [standardSoftware, setStandardSoftware] = useState<boolean>();
  const [protection, setProtection] = useState<boolean>();

  const [selectedDistribution, setSelectedDistribution] = useState<
    string | undefined
  >();
  const { selectedRole } = useContext(SelectedRoleContext);
  const { versionId, inLegalCheckOrDone, processStatusAsString } = useContext(
    ProductDetailsContext
  );

  useEffect(() => {
    setEditing(false);
  }, [processStatusAsString]);

  const isEditable = editing && !inLegalCheckOrDone;

  const { loading, data, error } = useQuery(PRODUCT_INFOBOX, {
    variables: { id: versionId },
    onCompleted(result) {
      const version = result.Version;
      const product = result.Version?.product;
      setDescription(product?.description);
      setTypes(product?.type);
      setTargetDate(version?.targetDate);
      setHighPriority(product?.highPriorityRequest);
      setSelectedDistribution(product?.selectedDistribution?.id);
      setStandardSoftware(product?.standardSoftware);
      setProtection(product?.protection);
    },
  });

  const [editDetailsMutation] = useEditProductDetailsMutation({
    variables: {
      versionId,
      targetDate,
      types,
      highPriorityRequest: highPriority,
      description,
      selectedDistribution,
      standardSoftware,
      protection,
    },
    refetchQueries: [
      PRODUCT_INFOBOX,
      LEGAL_CHECK_POSSIBLE,
      LEGAL_CHECK_DONE_POSSIBLE,
    ],
  });

  if (loading) return <InfoboxPlaceholder />;
  if (error)
    return (
      <Message
        id="ProductInfoBoxError"
        error
        icon="times circle"
        content={`Error ${error.message}`}
      />
    );

  if (data) {
    return (
      <Segment id="InfoBox" raised>
        <InfoBoxHeader open={open} setOpen={setOpen} />
        {open && (
          <Grid relaxed padded>
            {inLegalCheckOrDone && editing && (
              <Grid.Row>
                <Grid.Column>
                  <Message
                    id="ProductDetailsWarningMessage"
                    warning
                    icon="exclamation triangle"
                    content={t('noEditLegalCheckMessage')}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row columns={2} divided centered>
              <Grid.Column width={10}>
                {/* First Row */}
                <Grid columns={2}>
                  <Grid.Column width={10}>
                    <DescriptionItem
                      editing={editing}
                      description={description}
                      setDescription={setDescription}
                      maxDescriptionLines={maxDescriptionLines}
                    />
                  </Grid.Column>
                  <Grid.Column width={6} textAlign="center">
                    <EditProductButton
                      editing={editing}
                      setEditing={setEditing}
                      types={types}
                      editDetailsMutation={editDetailsMutation}
                    />
                  </Grid.Column>
                </Grid>
                {/* Second Row */}
                <Grid columns="equal">
                  <Grid.Column>
                    <TargetDateItem
                      editing={editing}
                      targetDate={targetDate}
                      setTargetDate={setTargetDate}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <DistributionItem
                      editing={isEditable}
                      distribution={selectedDistribution}
                      setDistribution={setSelectedDistribution}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <ProductTypeItem
                      editing={isEditable}
                      productType={types}
                      setProductType={setTypes}
                    />
                  </Grid.Column>
                </Grid>
              </Grid.Column>
              <Grid.Column width={6}>
                <Item.Group>
                  <HighPriorityRequestItem
                    editing={editing}
                    highPriority={highPriority}
                    setHighPriority={setHighPriority}
                  />
                  {[Role.oso, Role.legal].includes(selectedRole) && (
                    <PriorityItem priority={data?.Version?.priority} />
                  )}
                  <ProtectionItem
                    protection={protection}
                    setProtection={setProtection}
                    editing={isEditable}
                  />
                  <StandardSoftwareItem
                    standardSoftware={standardSoftware}
                    setStandardSoftware={setStandardSoftware}
                    editing={isEditable}
                  />
                </Item.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </Segment>
    );
  }
  return <div />;
};

export default ProductInfoBox;
