import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal, Select } from 'semantic-ui-react';
import {
  SelectionTypes,
  SoftwareSelect,
} from '../../../../wizard/WizardConstants';
import WizardFormFieldLabel from '../../../../wizard/WizardFormFieldLabel';

interface Props {
  open: boolean;
  setOpen(open: boolean): void;
  standardSoftware: boolean | undefined;
  setStandardSoftware: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
}

/**
 * The Modal is used for InfoBox Standard Software Request Editing
 *
 * @param {object} props {open, setOpen, standardSoftware, setStandardSoftware }
 * @returns {JSX.Element} The Modal is used for UserInfoBox Standard Software Editing
 */
const StandardSoftwareModal = (props: Props): JSX.Element => {
  const [t] = useTranslation(['buttons', 'productDetails', 'newProductWizard']);
  const { open, setOpen, standardSoftware, setStandardSoftware } = props;

  return (
    <Modal
      size="tiny"
      id="StandardSoftwareModal"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      <Modal.Header content={t('editStandardSoftware', { ns: 'buttons' })} />
      <Modal.Content>
        <Form
          id="StandardSoftwareModalForm"
          style={{ width: '50%', margin: '0 auto' }}
        >
          <WizardFormFieldLabel
            nameText={t('productDetails.softwareType', {
              ns: 'newProductWizard',
            })}
            infoText={t('productDetails.softwareType-info', {
              ns: 'newProductWizard',
            })}
          />
          <Form.Field
            control={Select}
            options={SoftwareSelect()}
            value={standardSoftware}
            placeholder={t('pleaseSelect', {
              ns: 'newProductWizard',
            })}
            onChange={(_e: ChangeEvent, { value }: SelectionTypes) => {
              setStandardSoftware(value);
            }}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          id="StandardSoftwareModalSaveChangesButton"
          style={{ margin: '0 auto', padding: '1rem 5rem' }}
          disabled={!!standardSoftware && standardSoftware === undefined}
          primary
          onClick={() => {
            setStandardSoftware(standardSoftware);
            setOpen(false);
          }}
        >
          {t('close', { ns: 'buttons' })}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default StandardSoftwareModal;
